import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { Inject, Injectable } from "@angular/core";
import { uiClientsConfig , reservedCodeCategories} from 'config';

@Injectable()
export class routeGuardService implements CanActivate {
    redirectURI: string;
    constructor(private cookieService: CookieService, private location: Location) { }

    appsRedirectUrl = "https://" + uiClientsConfig.customerUrlPrefix + "-admin." + uiClientsConfig.reservedUrlDomain;
    appsRedirectURl2 = "https://" + uiClientsConfig.customerUrlPrefix + "-focus." + uiClientsConfig.reservedUrlDomain;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        var customerUrlPrefix = window.location.hostname.split("-")[0];
        customerUrlPrefix = customerUrlPrefix + "-admin";
        var cookie = this.cookieService.get(customerUrlPrefix);
        if (cookie) {
            return true;
        } else {
            this.redirectURI = this.location.path(true);
            let exactRedirectURI = this.appsRedirectURl2 + this.redirectURI;
            var authUrl = this.appsRedirectUrl + "/auth?redirecturl=" + encodeURIComponent(exactRedirectURI) + "&client_id=" + reservedCodeCategories.reservedPasswordGrantTypeClientId;
            window.location.href = authUrl;
            return false;
        }

    }
}