export interface callLoggingObject {
    orgEntityId?: string;
    callSid?: string;
    callStatus?: string;
    callDescription?: string;
    callRawData?: any;
    providerId?: string;
    callFrom?: string;
    callTo?: string;
    isOutboundCall?: boolean;
    phoneNumber?: string
}

export interface callObject {
    conferenceId?: string;
    callerId?: string;
    muted?: boolean;
    isHold?: boolean;
    twilioToNumber?: string;
}

interface taskqueues {
    statistics?: any;
    realtimeStats?: any;
    cumulativeStats?: any
}
export interface workspace {
    activities?: any;
    workflows?: any;
    taskqueues?: taskqueues;
    workers?: taskqueues;
    tasks?: any;
    statistics?: any;
    realtimeStats?: any;
    cumulativeStats?: any
}

export interface attributes {
    from_country?: string;
    called?: string;
    to_country?: string;
    to_city?: string;
    originalInboundTask?: boolean;
    selected_product?: string;
    to_state?: string;
    caller_name?: string;
    caller_country?: string;
    call_sid?: string;
    workflowSid?: string;
    account_sid?: string;
    from_zip?: string;
    from?: string;
    direction?: string;
    called_zip?: string;
    caller_state?: string;
    to_zip?: string;
    called_country?: string;
    from_city?: string;
    called_city?: string;
    caller_zip?: string;
    api_version?: string;
    called_state?: string;
    from_state?: string;
    application_sid?: string;
    caller?: string;
    caller_city?: string;
    to: string;
    selectedWorker?: string[];
    conference?: any;
    isOutboundConferenceTask?: boolean;
    isOriginalCallOutbound?: boolean;
    callerName?: string;
    isTransferTask?: boolean;
    isConferenceTask?: boolean;
    orgEntityId?: string;
}

interface task {
    workspaceSid?: string;
    workspace?: workspace;
    assignmentStatus?: string;
    dateUpdated?: string;
    taskQueueEnteredDate?: string;
    age?: number;
    sid?: string;
    accountSid?: string
    priority?: number;
    reason?: string | null;
    taskQueueSid?: string;
    workflowFriendlyName?: string
    timeout?: number;
    attributes: attributes;
    dateCreated?: string;
    taskChannelSid?: string;
    addons?: any;
    taskChannelUniqueName?: string;
    workflowSid?: string;
    workflow?: taskqueues;
    taskQueueFriendlyName?: string
}

export interface reservation {
    task: task;
    sid?: string;
    workerSid?: string;
    worker?: taskqueues;
    taskSid: string;
    dateUpdated?: string;
    workspaceSid?: string;
    workspace?: workspace
    reservationStatus?: string;
    accountSid?: string;
    workerName?: string;
    dateCreated?: string
    accept(): any;
    conference(
        arg1: null,
        arg2: null,
        arg3: null,
        arg4: null,
        callback: ConferenceCallback,
        options: ConferenceOptions
    ): void;
    call(
        arg1: null,
        arg2: string,
        arg3: null,
        arg4: string,
        arg5: null
    ): void;
    __proto__?: any;
    data?: any[];
    hasNext?: boolean
    hasPrevious?: boolean
    page?: number
    pageSize?: number
}

export interface ConferenceCallback {
    (error: any | null, reservation: reservation): void;
}

export interface ConferenceOptions {
    ConferenceStatusCallback: string;
    ConferenceStatusCallbackEvent: string;
    EndConferenceOnExit: string;
    EndConferenceOnCustomerExit: string;
    ConferenceRecord: string;
    ConferenceRecordingStatusCallback: string;
}

export interface outboundConferenceCallInterface {
    conferenceSid: string
    customerCallSid: string
}

export interface validateOutboundCall {
    fromPhoneNumber: string
    isActionSuccess: boolean
    isToPhoneNumberValid?: boolean
    patientId?: string
    workflowId?: string
}

export interface sendTwilioCallActivityStatus {
    isActionSuccess: boolean;
    orgEntityId?: string
}