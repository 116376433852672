import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-timer',
    template: '<span class="call-time fw-600">{{ timerValue }}</span>',
})
export class TimerComponent implements OnInit, OnDestroy {
    private timerId: any;
    public timerValue: string = '00:00';

    ngOnInit(): void {
        this.startTimer();
    }

    ngOnDestroy(): void {
        this.stopTimer();
    }

    private startTimer(): void {
        let seconds = 0;

        this.timerId = setInterval(() => {
            seconds++;
            this.timerValue = this.formatTime(seconds);
        }, 1000);
    }

    private stopTimer(): void {
        clearInterval(this.timerId);
    }

    private formatTime(seconds: number): string {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const formattedSeconds =
            remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
        return `${formattedMinutes}:${formattedSeconds}`;
    }
}
