import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { getTwilioDeviceOrWorkerToken } from '../twilio-connection/twilio-connection';
import { uiClientsConfig } from 'config';

@Injectable({
  providedIn: 'root'
})
export class TwilioWorkerService {

  subDomain = window.location.host.split("-focus")[0];
  twilioApiPath: string = `https://${this.subDomain}-twilio.${uiClientsConfig.reservedUrlDomain}/api/twilio-public-endpoints`;
  headers: HttpHeaders;
  oauthUserId: string = "";
  userDetails: string = `/api/v1/platform/oauth-user`;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json;charset=utf-8')
  }

  public getTwilioWorker(workerName: any) {
    return firstValueFrom(this.http.get(this.twilioApiPath + `/worker?name=${workerName}`).pipe((responseData) => {
      return responseData;
    }));
  }

  public getTwilioWorkerToken(twilioWorkerId: any) {
    return firstValueFrom(this.http.post<getTwilioDeviceOrWorkerToken>(this.twilioApiPath + `/worker-token`, { workerId: twilioWorkerId }).pipe((responseData) => {
      return responseData;
    }));
  }

  public getTwilioDeviceToken(workerName: any) {
    return firstValueFrom(this.http.post<getTwilioDeviceOrWorkerToken>(this.twilioApiPath + `/device-token`, { clientName: workerName }).pipe((responseData) => {
      return responseData;
    }));
  }

  public leaveOngoingCall(twilioCallObject: any) {
    return firstValueFrom(this.http.post(this.twilioApiPath + `/leave-call`, twilioCallObject).pipe((responseData) => {
      return responseData;
    }));
  }

  public hangOngoingCall(twilioCallObject: any) {
    return firstValueFrom(this.http.post(this.twilioApiPath + `/hang-call`, twilioCallObject).pipe((responseData) => {
      return responseData;
    }));
  }

  public callMuteStatus(twilioCallObject: any) {
    return firstValueFrom(this.http.post(this.twilioApiPath + `/mute-status`, twilioCallObject).pipe((responseData) => {
      return responseData;
    }));
  }

  public callHoldStatus(twilioCallObject: any) {
    return firstValueFrom(this.http.post(this.twilioApiPath + `/hold-status`, twilioCallObject).pipe((responseData) => {
      return responseData;
    }));
  }
}
