import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internet-connectivity-modal',
  templateUrl: './internet-connectivity-modal.component.html',
  styleUrls: ['./internet-connectivity-modal.component.css']
})
export class InternetConnectivityModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
