import { Directive, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appRandomBackgroundColorGenerator]'
})
export class RandomBackgroundColorGeneratorDirective {

  @Input('appRandomBackgroundColorGenerator') id!: string;

  private defaultColor: string = 'rgba(20, 32, 69, 0.5)';

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    let bgColor;

    if (this.id) {
      bgColor = this.generateUniqueColorFromID(this.id);
    } else {
      const x = this.randomInteger(255);
      const y = this.randomInteger(255);
      const z = this.randomInteger(255);
      const opacity = 0.5;
      bgColor = `rgb(${x}, ${y}, ${z},${opacity})`;
    }

    this.renderer.setStyle(this.el.nativeElement, 'background-color', bgColor);
  }
  randomInteger(max) {
    return Math.floor(Math.random() * (max + 1));
  }

  private generateUniqueColorFromID(id: string): string {
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
      hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }

    const r = (hash >> 16) & 255;
    const g = (hash >> 8) & 255;
    const b = hash & 255;
    const opacity = 0.5;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}
