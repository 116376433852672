// <reference types="@angular/localize" />

import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { provideRouter, RouterModule } from '@angular/router';
import { AppRoutes } from './app/app-routing';
import { AppComponent } from './app/app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './app/interceptors/auth.interceptor';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { FocusComponent } from './app/apps/focus/focus.component';
import { PatientsComponent } from './app/apps/focus/patients/patients.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { routeGuardService } from './app/apps/route-guard/routing-guard.sevice';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { EditorModule , TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { UnsavedChangesGuard } from './app/apps/unsaved-changes.guard';
import { MatDialogModule } from '@angular/material/dialog';
if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

bootstrapApplication(AppComponent, {
  providers: [
    {provide: TINYMCE_SCRIPT_SRC, useValue:'tinymce/tinymce.min.js'},
    importProvidersFrom(MatDatepickerModule, MatNativeDateModule,MatDialogModule),
  importProvidersFrom(RouterModule.forRoot(AppRoutes),HttpClientModule),
  routeGuardService,
  UnsavedChangesGuard,
  importProvidersFrom(
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
),
  provideAnimations(),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
}).catch(err => console.error(err));

