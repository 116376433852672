import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { interval, Subscription } from 'rxjs';
import { agents } from '../../real-time-dashboard-interface';

@Component({
  selector: 'app-agents-marquee',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './agents-marquee.component.html',
  styleUrls: ['./agents-marquee.component.css']
})
export class AgentsMarqueeComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() data: agents[] = [];
  @Input() heading: string = "";
  @ViewChild("element") element: ElementRef = {} as ElementRef;
  @ViewChild("elementCopy") elementCopy: ElementRef = {} as ElementRef;

  constructor() { }

  agents: agents[] = [];
  marquee: Subscription = new Subscription();
  pauseMarquee: boolean = false;
  isHovering: boolean = false;

  agentTrackBy(index: number, agents: agents): string {
    return agents.oauthUserId;
  }

  pauseMarqueeOnHover(): void {
    this.pauseMarquee = true;
    this.isHovering = true;
  }

  continueMarquee(): void {
    this.pauseMarquee = false;
    this.isHovering = false;
  }

  startMarquee(): void {
    let positionTop: number = this.element.nativeElement.parentElement.offsetHeight;
    let positionTopCopy: number = positionTop;
    this.marquee = interval(10).subscribe(() => {
      if (!this.pauseMarquee && this.agents.length > 6) {
        let elementHeight: number = this.element.nativeElement.offsetHeight;
        this.element.nativeElement.style.top = --positionTop + "px";
        this.elementCopy.nativeElement.style.top = --positionTopCopy + "px";
        if (positionTop % 60 == 0) {
          this.pauseMarquee = true;
          setTimeout(() => {
            if (!this.isHovering)
              this.pauseMarquee = false;
          }, 700);
        }
        if (elementHeight == -positionTop) {
          positionTop = elementHeight;
        }
        if ((2 * elementHeight) == -positionTopCopy) {
          positionTopCopy = 0;
        }
      }
      else if (this.agents.length <= 6) {
        this.element.nativeElement.style.top = 0 + "px";
        positionTop = positionTopCopy = this.element.nativeElement.parentElement.offsetHeight;
      }
    });
  }

  ngAfterViewInit(): void {
    this.startMarquee();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.agents = changes["data"].currentValue;
  }

  ngOnDestroy(): void {
    this.marquee?.unsubscribe();
    this.marquee = {} as Subscription;
  }

  ngOnInit(): void {
  }

}
