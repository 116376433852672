import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-navigation-confirmation-prompt',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './navigation-confirmation-prompt.component.html',
  styleUrls: ['./navigation-confirmation-prompt.component.css']
})
export class NavigationConfirmationPromptComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,public dialogRef: MatDialogRef<NavigationConfirmationPromptComponent>) { }

  cancel(input:boolean) {
    this.dialogRef.close(input);
  }

  ngOnInit(): void {
  }

}
