<div
  style="
    padding: 20px;
    padding-bottom: 5px !important;
    box-shadow: -1px 7px 18px rgba(197, 197, 197, 0.25);
    height: 185px;
  "
>
  <div class="row" style="margin-bottom: 12px">
    <div class="col-sm-10" style="padding-left: 5px !important">
      <span style="color: #22464f; font-size: 20px; font-weight: 700"
        >{{ heading }} - <span *ngIf="agents.length < 10">0</span
        >{{ agents.length }}</span
      >
    </div>
  </div>
  <div
    class="row"
    (mouseover)="pauseMarqueeOnHover()"
    (mouseleave)="continueMarquee()"
    style="overflow: hidden; height: calc(100% - 40px)"
  >
    <div class="row mx-0 w-100" #element style="position: relative; top: 0">
      <div class="row mx-0 w-100" *ngIf="agents.length">
        <div
          class="col-sm-4 info-cell"
          *ngFor="let obj of agents; trackBy: agentTrackBy"
          style="padding-left: 5px !important; padding-right: 5px; height: 60px"
        >
          <div
            style="
              display: flex;
              align-items: center;
              background: #ebf5e9;
              border: 0.5px solid #a2d196;
            "
            class="details"
          >
            <div style="padding: 0px 5px 0px 8px">
              <img
                class="activeImg"
                [src]="obj.profilePictureUrl"
                loading="lazy"
                *ngIf="
                  obj.profilePictureUrl !== 'img/default-user.png' &&
                  obj.profilePictureUrl !== 'img/default-user.svg'
                "
                style="width: 28px; height: 28px; border-radius: 50%"
              />
              <span
                style="width: 40px; height: 40px; padding-top: 10px"
                *ngIf="
                  obj.profilePictureUrl === 'img/default-user.png' ||
                  obj.profilePictureUrl === 'img/default-user.svg'
                "
                class="initials d-flex justify-content-center align-items-center"
                appRandomBackgroundColorGenerator
                >{{ obj.initials }}</span
              >
            </div>
            <div style="line-height: 1.3; width: calc(100% - 45px)">
              <p
                style="
                  margin-bottom: 0px;
                  font-size: 18px;
                  color: #22464f;
                  font-weight: 700;
                "
                appTextEllipsis
                class="text-ellipsis text-capitalize"
              >
                {{ obj.displayName }}
              </p>
              <span style="font-size: 13px; color: red">
                {{ obj.time }}
                <span style="color: #22464f; font-size: 13px"
                  >| {{ obj.dayCallCount }} calls</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row mx-0 w-100"
        *ngIf="agents.length > 6"
        style="height: 60px"
      ></div>
    </div>

    <!-- Only for animation -->
    <div
      class="row mx-0 w-100"
      #elementCopy
      style="position: relative"
      *ngIf="agents.length > 6"
    >
      <div class="row mx-0 w-100">
        <div
          class="col-sm-4 info-cell"
          *ngFor="let obj of agents; trackBy: agentTrackBy"
          style="padding-left: 5px !important; padding-right: 5px; height: 60px"
        >
          <div
            style="
              display: flex;
              align-items: center;
              background: #ebf5e9;
              border: 0.5px solid #a2d196;
            "
            class="details"
          >
            <div style="padding: 0px 5px 0px 8px">
              <img
                class="activeImg"
                [src]="obj.profilePictureUrl"
                loading="lazy"
                *ngIf="
                  obj.profilePictureUrl !== 'img/default-user.png' &&
                  obj.profilePictureUrl !== 'img/default-user.svg'
                "
                style="width: 28px; height: 28px; border-radius: 50%"
              />
              <span
                style="width: 40px; height: 40px; padding-top: 10px"
                *ngIf="
                  obj.profilePictureUrl === 'img/default-user.png' ||
                  obj.profilePictureUrl === 'img/default-user.svg'
                "
                class="initials d-flex justify-content-center align-items-center"
                appRandomBackgroundColorGenerator
                >{{ obj.initials }}</span
              >
            </div>
            <div style="line-height: 1.3; width: calc(100% - 45px)">
              <p
                style="
                  margin-bottom: 0px;
                  font-size: 18px;
                  color: #22464f;
                  font-weight: 700;
                "
                appTextEllipsis
                class="text-ellipsis text-capitalize"
              >
                {{ obj.displayName }}
              </p>
              <span style="font-size: 13px; color: red">
                {{ obj.time }}
                <span style="color: #22464f; font-size: 13px"
                  >| {{ obj.dayCallCount }} calls</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0 w-100" style="height: 60px"></div>
    </div>
  </div>
</div>
