<div class="row" style="padding-left: 15px;">
    <div class="col-md-2" style="padding: 0px;">
        <div class="sub-block align-items-center" style="background-color: #D4EAD9;">
            <p style="color: #20444D;font-size: 35px;font-weight: 500;">
                {{ dashboardData?.callStats["0"]?.incomingCalls || '0' }}
            </p>
            <span class="fw-600" style="color: #20444D;font-size: 19px;">Incoming Calls</span>
        </div>
    </div>
    <div class="col-md-2" style="padding: 0px;">
        <div class="sub-block align-items-center" style="background-color: #21464F;">
            <p style="color: #FFFFFF;font-size: 35px;font-weight: 500;">
                {{ dashboardData?.callStats["0"]?.outgoingCalls || '0' }}
            </p>
            <span class="fw-600" style="color: #FFFFFF;font-size: 19px;">Outgoing Calls</span>
        </div>
    </div>
    <div class="col-md-2" style="padding: 0px;">
        <div class="sub-block align-items-center" style="background-color: #B95B58;">
            <p style="color: #FFFFFF;font-size: 35px;font-weight: 500;">
                {{ dashboardData?.callStats["0"]?.pendingCalls || '0' }}
            </p>
            <span class="fw-600" style="color: #FFFFFF;font-size: 19px;">Current in Call Queue</span>
        </div>
    </div>
    <div class="col-md-2" style="padding: 0px;">
        <div class="sub-block align-items-center" style="background-color: #21464F;">
            <p style="color: #FFFFFF;font-size: 35px;font-weight: 500;">
                {{ (dashboardData?.callStats["0"]?.abandonRate) ? (dashboardData.callStats["0"].abandonRate * 100).toFixed(2) : '0' }}%
            </p>
            <span class="fw-600" style="color: #FFFFFF;font-size: 19px;">Abandonment Rate</span>
        </div>
    </div>
    <div class="col-md-2" style="padding: 0px;">
        <div class="sub-block align-items-center" style="background-color: #D4EAD9;">
            <p style="color: #21464F;font-size: 35px;font-weight: 500;">
                {{ dashboardData?.callStats["0"]?.avgWaitTime || '0.00' }}
            </p>
            <span class="fw-600" style="color: #21464F;font-size: 19px;">Avg Wait Time</span>
        </div>
    </div>
    <div class="col-md-2" style="padding: 0px;">
        <div class="sub-block align-items-center" style="background-color: #21464F;">
            <p style="color: #FFFFFF;font-size: 35px;font-weight: 500;">
                {{ dashboardData?.callStats["0"]?.availableWorkers || "0" }}
            </p>
            <span class="fw-600" style="color: #FFFFFF;font-size: 19px;">Agents Available</span>
        </div>
    </div>

</div>
<div class="row mx-0 w-100" style="padding: 15px 30px !important; margin-bottom: 5px;">

    <!-- INBOUND ON CALL -->
    <div class="col-sm-4">
        <app-agents-marquee [data]="agentInInboundOnCall" [heading]="'On Call - Inbound'"></app-agents-marquee>
    </div>

    <!-- OUTBOUND ON CALL -->
    <div class="col-sm-4">
        <app-agents-marquee [data]="agentInOutboundOnCall" [heading]="'On Call - Outbound'"></app-agents-marquee>
    </div>

    <!-- AFTER CALL -->
    <div class="col-sm-4">
        <app-agents-marquee [data]="agentInAfterCall" [heading]="'After Call'"></app-agents-marquee>
    </div>

</div>

<div class="row mx-0 w-100" style="padding: 15px 30px !important;margin-bottom: 5px;">

    <!-- TAKING CALLS -->
    <div class="col-sm-4">
        <app-agents-marquee [data]="agentInTakingCalls" [heading]="'Taking Calls'"></app-agents-marquee>
    </div>

    <!-- MAKING CALLS -->
    <div class="col-sm-4">
        <app-agents-marquee [data]="agentInMakingCalls" [heading]="'Making Calls'"></app-agents-marquee>
    </div>

    <!-- GETTING READY -->
    <div class="col-sm-4">
        <app-agents-marquee [data]="agentInGettingReady" [heading]="'Getting Ready'"></app-agents-marquee>
    </div>

</div>

<div class="row mx-0 w-100" style="padding: 15px 30px !important; margin-bottom: 5px;">

    <!-- PERSONAL -->
    <div class="col-sm-4">
        <app-agents-marquee [data]="agentInPersonal" [heading]="'Personal'"></app-agents-marquee>
    </div>

    <!-- LUNCH -->
    <div class="col-sm-4">
        <app-agents-marquee [data]="agentInLunch" [heading]="'Lunch'"></app-agents-marquee>
    </div>

    <!-- BREAK -->
    <div class="col-sm-4">
        <app-agents-marquee [data]="agentInBreak" [heading]="'Break'"></app-agents-marquee>
    </div>

</div>

<div class="row mx-0 w-100" style="padding: 15px 30px !important; margin-bottom: 5px;">

    <!-- TRAINING -->
    <div class="col-sm-4">
        <app-agents-marquee [data]="agentInTraining" [heading]="'Training'"></app-agents-marquee>
    </div>

    <!-- MEETING -->
    <div class="col-sm-4">
        <app-agents-marquee [data]="agentInMeeting" [heading]="'Meeting'"></app-agents-marquee>
    </div>

    <!-- OTHERS -->
    <div class="col-sm-4">
        <app-agents-marquee [data]="agentInOthers" [heading]="'Others'"></app-agents-marquee>
    </div>

</div>