import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  standalone : true,
  selector: '[disallowSpaces]'
})
export class DisallowSpacesDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const start = input.selectionStart;
    input.value = input.value.trimStart();
    input.selectionEnd = start;
    event.preventDefault();
  }
}
