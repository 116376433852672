import { callerDetail } from "../interface";

export interface SearchOrAssociatePatientModalComponentInterface {
    isActionSuccess?: boolean;
    openCreatePatientModal?: boolean;
    data?: callerDetail[];
}

export interface searchMember {
    data: patientDetails[];
    count: number;
}

interface patientDetails {
    patientId: string;
    externalId: string;
    firstName: string;
    gender: string;
    lastName: string;
    patientOauthUserId: string;
    initials: string;
    emailAddress: string;
    profilePictureUrl: string;
    dateOfBirth: string;
    cellPhone: string;
    guarantorPhoneNumber: string | null
}
export interface paginationResult {
    count: number,
    data: paginationData[]
}
export interface paginationData {
    firstName: string,
    lastName: string,
    gender: string,
    emailAddress: string,
    cellPhone: string | null | undefined,
    guarantorPhoneNumber: string | null | undefined,
    dateOfBirth: string,
    externalId: string | null,
    initials: string,
    patientId: string,
    patientOauthUserId: string | null,
    profilePictureUrl: string | null
}

export interface associateMemberResult {
    data: getCallPickedDetails[],
    isActionSuccess: boolean
}
export interface getCallPickedDetails {
    clientName: string,
    createdDateTimeUtc: string,
    dateOfBirth: string,
    guarantorEmail: string,
    guarantorName: string,
    guarantorPhoneNumber: string | null | undefined,
    medicalRecordNumber: string,
    orgEntityId: string,
    patientAddress: string,
    patientEmail: string,
    patientExternalId: string,
    patientInitials: string,
    patientName: string,
    patientPhoneNumber: string,
    profilePictureUrl: string,
    psychographicSegment: string,
}
export interface callAuditParameters {
    callSid: string | undefined,
    fromNumber: string | undefined,
    isOutboundCall: boolean | undefined,
    providerId: string | undefined,
    rawCallData: any | undefined,
    toNumber: string | undefined
}
export interface unknownPatientObject {
    cellPhone: string,
    dateOfBirth: string,
    emailAddress: string,
    firstName: string,
    lastName: string,
    pageNumber: number
}