import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { searchMember,unknownPatientObject } from './search-or-associate-patient-modal-interface';

@Injectable({
  providedIn: 'root'
})
export class SearchOrAssociatePatientModalService {
  constructor(private http: HttpClient) { }

  inboxFocusApi = 'api/v1/platform/inbox-focus';

  searchUnknownPatient(searchObj:unknownPatientObject): Observable<searchMember> {
    let obj = {
      limit: 3,
      pageNumber: searchObj.pageNumber,
      firstName: searchObj.firstName,
      lastName: searchObj.lastName,
      dateOfBirth: searchObj.dateOfBirth,
      emailAddress: searchObj.emailAddress,
      cellPhone: searchObj.cellPhone,
      guarantorPhoneNumber: searchObj.cellPhone
    }

    return this.http.get<searchMember>(this.inboxFocusApi + `/getPatients`, { params: obj })
  }
}
