import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { advanceSearchCriteria, advanceSearchObject, getCodeValueByCategoryIdResult, getUserStatus } from '../apps/focus/patients/patients-interface';
import axios from 'axios';
import { getCodeCategory, getUserProfileDetail, getWorkspaceActivities } from '../apps/focus/navbar/interface';
import { uiClientsConfig, reservedCodeCategories } from 'config';

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  headers: HttpHeaders;
  oauthUserId: string = "";
  loginUrl: string = `/api/v1/idp/oauth2-token`;
  getAllPatientListUrl: string = `/api/v1/platform/patient-details`;
  codeCategoryUrl: string = `/api/v1/platform/code-value-by-category-lookup`;
  userDetails: string = `/api/v1/platform/oauth-user`;
  twilioWorkerDetails: string = `/api/v1/platform/twilio-task-router`;
  providerChannelAvailabilityApi: string = `/api/v1/platform/provider-channel-availability`;
  postUserStatus: string = '/api/v1/platform/provider-channel-availability';
  getChannelStatus: string = `/api/v1/platform/code-value-by-category-lookup`;
  deleteChannelStatus: string = `/api/v1/platform/provider-channel-availability/channelId`;
  deleteOauthUserChannel: string = `/api/v1/platform/provider-channel-availability/oauthUserId`;
  bulkActionSms: string = '/api/v1/platform/patient-details/bulkCreatePatientFocusActivityLog/SMS';
  exportDataApiPath: string = "/api/v1/platform/patient-details/getAllPatientData";
  getVirtualAppUserDetailsApi: string = "api/v1/platform/virtual-care-app-user-detail";
  reservedCodeCategoryOauthUserChannelId: string = reservedCodeCategories.reservedCodeCategoryOauthUserChannelId;  userApplicationsApiPath=`/api/v1/platform/current-user-application`;
  getCommunicationTabPermissionApi: string = "/api/v1/platform/brand-management-lookup";


  constructor(private http: HttpClient, private cookieService: CookieService) {
    var customerUrlPrefix = window.location.hostname.split("-")[0];
    this.oauthUserId = this.cookieService.get(customerUrlPrefix + '_providerUserId');
    this.headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  }

  public getAdvanceMemberSearchData(advanceSearchObject: advanceSearchObject): Observable<any> {
    return this.http.post(this.getAllPatientListUrl + "/advanced-patient-search", advanceSearchObject);
  }

  public getAllPatientData(limit: number, page: number, sortBy: string, sortOrder: string): Observable<any> {
    let currentPage: number = page;
    let maxLimit: number = limit;
    const obj = { "limit": maxLimit, "pageNumber": currentPage, "sortBy": sortBy, "sortOrder": sortOrder, 'offset': 0, 'recents': true };
    return this.http.get(this.getAllPatientListUrl, { params: obj });
  }

  public getAllUserApplications() {
    return this.http.get(this.userApplicationsApiPath).toPromise();
  }

  public searchedPatientData(limit: number, page: number, searchString: string): Observable<any> {
    let maxLimit: number = limit;
    let currentPage: number = page;
    const obj = { "limit": maxLimit, "pageNumber": currentPage, "sortBy": 'patientName', "sortOrder": 'ASC', 'search': searchString };
    return this.http.get(this.getAllPatientListUrl + "/patient-search", { params: obj });
  }

  public getValidPermission(permissionName: string, isSubAction: boolean): Observable<any> {
    return this.http.get(this.getVirtualAppUserDetailsApi + "/" + permissionName + "/" + isSubAction);
  }

  public sendBulkActionSms(obj: any) {
    return this.http.post(this.bulkActionSms, obj);
  }

  getUserProfileDetail(): Observable<getUserProfileDetail> {
    return this.http.get<getUserProfileDetail>(this.userDetails + `/focus-user/${this.oauthUserId}`);
  }

  getCodeValueByCategoryId(codeCategoryId: string): Observable<getCodeValueByCategoryIdResult> {
    return this.http.get<getCodeValueByCategoryIdResult>(`${this.codeCategoryUrl}/${codeCategoryId}`)
  }

  getProviderChannelAvailabilityApi() {
    return this.http.get(this.providerChannelAvailabilityApi + `/${this.oauthUserId}`);
  }

  getCodeCategory(): Observable<getCodeCategory> {
    return this.http.get<getCodeCategory>(this.getChannelStatus + `/${this.reservedCodeCategoryOauthUserChannelId}`);
  }

  postOauthUserChannel(getUserStatus: getUserStatus): Observable<any> {
    return this.http.post(this.postUserStatus, getUserStatus, { headers: this.headers });
  }

  updateTwilioWorkerStatusInDB(getUserStatus: getUserStatus): Observable<any> {
    return this.http.post(this.postUserStatus, getUserStatus, { headers: this.headers });
  }

  deleteUserAvailability(getUserStatus: getUserStatus) {
    return this.http.post(this.deleteChannelStatus + `/${getUserStatus.channelId}`, getUserStatus, { headers: this.headers });
  }

  deleteOauthUser(deleteOauthUserId: string, incomingObject: any) {
    return this.http.post(this.deleteOauthUserChannel + `/${deleteOauthUserId}`, incomingObject)
  }

  exportData(isAllSelected: boolean, excludingArray: string[], includingArray: string[], advanceSearchCriteria: advanceSearchCriteria[], search: string, sortBy: string, sortOrder: string): Observable<any> {
    return this.http.post(this.exportDataApiPath, { isAllSelected, excludingArray, includingArray, advanceSearchCriteria, search, sortBy, sortOrder }, { responseType: 'text' })
      .pipe(responseData => {
        return responseData;
      })
  }

  getTwilioWorker() {
    return this.http.get(this.twilioWorkerDetails + "/twilio-worker");
  }

  getWorkspaceActivities(workspaceId: string): Observable<getWorkspaceActivities> {
    return this.http.get<getWorkspaceActivities>(this.twilioWorkerDetails + "/worker-activity/" + workspaceId);
  }

  unHoldUserStatus({ conferenceId, callerId, isHold, twilioToNumber }) {
    let customerUrlPrefix = window.location.hostname.split("-")[0];
    return axios.post(`https://${customerUrlPrefix}-twilio.${uiClientsConfig.reservedUrlDomain}/api/twilio-public-endpoints/hold-status`, { conferenceId, callerId, isHold, twilioToNumber })
  }

  oauthUserLoginPooling(userName: string) {
    return this.http.put(this.userDetails + `/update-user-login-status/${userName}`, {
      isLoggedIn: true
    })
      .toPromise()
      .catch((error: any) => {
        // Handle the error here
        console.error('An error occurred:', error);
        // Optionally, you can rethrow the error to propagate it to the caller
        throw error;
      });
  }


  getCommunicationTabPermission(domain: string) {
    const obj = {
      "configObjects": [
        "focusBrandConfiguration"
      ]
    }
    return this.http.post<any>(this.getCommunicationTabPermissionApi + `/${domain}/custom`, obj)
  }

}
