<!-- Call Feature -->
<div class="main-wrapper" *ngIf="isCallPicked" cdkDrag [cdkDragBoundary]="viewportBoundary">
  <div class="twilio-window" *ngIf="isCallConnected && !isCall" [hidden]="minimize || isCallDisconnectOptionsVisible">
    <div class="call-info">
      <span class="fw-600" style="width: calc(100% - 78px);">
        <span class="patient-name">
          {{associatedPatientName||patientName||incomingCall.task?.attributes?.caller_name||formattedPhoneNumber}}
        </span>
        <span *ngIf="totalAgentCount>1"> and {{this.totalAgentCount}} others</span>
      </span>

      <span>
        <app-timer style="margin: 0 8px;"></app-timer>&nbsp;&nbsp;<span (click)="minimize=true"><i
            class="fa fa-minus text-white cursor-pointer"></i></span>
      </span>
    </div>
    <div class="patient-profile-image d-flex justify-content-center align-items-center h-100">
      <img (click)="openConferenceModal()" loading="lazy"
        *ngIf="profilePicture && profilePicture !== 'img/default-user.png' && profilePicture !== 'img/default-user.svg' && profilePicture !== 'img/pbadmin.svg'"
        [src]="profilePicture" style="cursor:pointer;width: 70px;border-radius: 50%;margin-right:10px;" alt="" />

      <span style="background-color: #4b7e52;" (click)="openConferenceModal()"
        class="initials d-flex justify-content-center align-items-center"
        *ngIf="profilePicture === 'img/default-user.png' || profilePicture === 'img/default-user.svg' ||  profilePicture === 'img/pbadmin.svg' || !profilePicture">{{patientInitials
        || "UP"}}</span>
      <span style="margin-left: 10px;" appRandomBackgroundColorGenerator (click)="openConferenceModal()"
        class="initials d-flex justify-content-center align-items-center"
        *ngIf="totalAgentCount>1">+{{this.totalAgentCount}}</span>
    </div>
    <div class="call-options-wrapper">
      <span class="twilio-call-options">
        <span class="call-pause text-white" *ngIf="!holdStatus" (click)="callHoldStatus()"><i class="fa fa-pause"
            aria-hidden="true"></i></span>
        <span class="call-pause text-white" *ngIf="holdStatus" (click)="callHoldStatus()"><i class="fa fa-play"
            aria-hidden="true"></i></span>
        <span class="call-mute text-white" *ngIf="!muteStatus" (click)="callMuteStatus()"><i class="fa fa-microphone"
            aria-hidden="true"></i></span>
        <span class="call-unmute text-white" *ngIf="muteStatus" (click)="callMuteStatus()"><i
            class="fa fa-microphone-slash" aria-hidden="true"></i></span>
        <span class="call-disconnect text-white" (click)="hangUpOrShowOptions(totalAgentCount);"><i class="fa fa-phone"
            aria-hidden="true"></i></span>
      </span>
    </div>
  </div>

  <div class="twilio-window-minimized" *ngIf="isCallConnected && !isCall"
    [hidden]="!minimize || isCallDisconnectOptionsVisible">
    <div class="call-info-minimized" style="width: calc(100% - 110px);">
      <span class="fw-600 text-white d-flex justify-content-between">
        <span class="text-ellipsis cursor-pointer patient-name" (click)="minimize=false;">{{
          associatedPatientName
          || patientName
          || incomingCall.task?.attributes?.caller_name
          || formattedPhoneNumber}}</span>
        <app-timer style="margin: 0 8px;"></app-timer>
      </span>
    </div>
    <span class="twilio-call-options">
      <span class="call-pause text-white" *ngIf="!holdStatus" (click)="callHoldStatus()"><i class="fa fa-pause"
          aria-hidden="true"></i></span>
      <span class="call-pause text-white" *ngIf="holdStatus" (click)="callHoldStatus()"><i class="fa fa-play"
          aria-hidden="true"></i></span>
      <span class="call-mute text-white" *ngIf="!muteStatus" (click)="callMuteStatus()"><i class="fa fa-microphone"
          aria-hidden="true"></i></span>
      <span class="call-unmute text-white" *ngIf="muteStatus" (click)="callMuteStatus()"><i
          class="fa fa-microphone-slash" aria-hidden="true"></i></span>
      <span class="call-disconnect text-white" (click)="hangUpOrShowOptions(totalAgentCount);">
        <i class="fa fa-phone" aria-hidden="true"></i></span>
    </span>
  </div>

  <div class="twilio-window call-disconnect-options"
    *ngIf="isCallDisconnectOptionsVisible && isCallConnected && !isCall">
    <button class="text-white w-100 bg-danger" (click)="hangUpCall();">End Call for All</button>
    <button class="text-white w-100" (click)="leaveCall();">Leave Call</button>
    <button class="text-white w-50" (click)="isCallDisconnectOptionsVisible=false;">Cancel</button>
  </div>
</div>

<div class="main-wrapper" *ngIf="isCall" cdkDrag [cdkDragBoundary]="viewportBoundary">
  <div class="twilio-window call-incoming" *ngIf="isCallConnected || isCallConnecting" queryParamsHandling="merge">
    <div class="d-flex flex-column h-100 w-100" style="background: #000000a6; border-radius: 8px;">
      <div class="call-info d-flex justify-content-center">
        <span class="fw-600">Call from
          <span class="patient-name">
            {{ formattedPhoneNumber }}
          </span>
        </span>
      </div>
      <div class="patient-profile-image d-flex justify-content-center align-items-start h-100">
        <span appRandomBackgroundColorGenerator class="patient-profile-details circular-animate">UP</span>
      </div>
      <div class="call-options-wrapper">
        <span class="twilio-options">
          <span class="call-mute text-white" *ngIf="isCallConnecting; else connected;" routerLink="/focus/inbox"
            [queryParams]="{tab: 0}">
            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
          </span>

          <ng-template #connected>
            <span class="call-unmute text-white" *ngIf="isCallConnected" (click)="connectIncomingCall(incomingCall)"
              routerLink="/focus/inbox" [queryParams]="{tab: 0}">
              <i class="fa fa-microphone-slash" aria-hidden="true"></i>
            </span>
          </ng-template>

        </span>
      </div>
    </div>
  </div>
</div>

<!-- Call Button & Dialer -->
<div class="main-wrapper" *ngIf="userStatus==='MAKING_CALLS' && isDialerVisible && dialPadPermission">
  <button class="call-button" (click)="openDialer();">
    <span class="call-icon">
      <i style="color:var(--dark-blue-grey);" class='fa fa-phone'></i>
    </span>
    <span id="button-heading" style="font-size:16px; margin-left: 10px;">Dialer</span>
  </button>
  <mat-accordion>
    <mat-expansion-panel hideToggle class="main" style="border-radius: unset !important" [expanded]="openMainPanel">
      <div class="dialer-wrapper">
        <div class="d-flex dialer-number-wrapper">
          <button class="dialer-call-btn above" disabled>
            <i class="fa fa-phone" aria-hidden="true"></i>
          </button>
          <input class="dialer-input form-control" #dialerInput [(ngModel)]="uiFormattedNumber" type="text"
            (paste)="onPaste($event)" (keyup)="formatNumber()" (keypress)="checkNumber($event)" maxlength="15" />
        </div>
        <div class="dialer-digits-row d-flex align-items-center">
          <button class="dialer-digit-btn left" (click)="clickingOnDialer('1')">1</button>
          <button class="dialer-digit-btn middle" (click)="clickingOnDialer('2')">2</button>
          <button class="dialer-digit-btn right" (click)="clickingOnDialer('3')">3</button>
        </div>
        <div class="dialer-digits-row d-flex align-items-center">
          <button class="dialer-digit-btn left" (click)="clickingOnDialer('4')">4</button>
          <button class="dialer-digit-btn middle" (click)="clickingOnDialer('5')">5</button>
          <button class="dialer-digit-btn right" (click)="clickingOnDialer('6')">6</button>
        </div>
        <div class="dialer-digits-row d-flex align-items-center">
          <button class="dialer-digit-btn left" (click)="clickingOnDialer('7')">7</button>
          <button class="dialer-digit-btn middle" (click)="clickingOnDialer('8')">8</button>
          <button class="dialer-digit-btn right" (click)="clickingOnDialer('9')">9</button>
        </div>
        <div class="dialer-digits-row d-flex align-items-center ">
          <button class="dialer-digit-btn left bg-white" (click)="clickingOnDialer('*')">*</button>
          <button class="dialer-digit-btn middle bg-white" (click)="clickingOnDialer('0')">0</button>
          <button class="dialer-digit-btn right bg-white" (click)="clickingOnDialer('#')">#</button>
        </div>
        <div class="dialer-digits-row d-flex justify-content-end align-items-center">
          <button class="dialer-digit-btn big dialer-call-btn middle" (click)="initializeCall()"
            [disabled]="isDisabled || isNumberValidating">
            <i class="fa fa-phone" aria-hidden="true"></i>
          </button>
          <button class="dialer-digit-btn big right bg-white" style="color: #b2b1c1;" (click)="backspace()">
            <img src="../../../../../assets/images/Cancel.svg" alt="" loading="lazy" height="20" width="26">
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>