import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { DashboardService } from '../dashboard.service';
import { CookieService } from 'ngx-cookie-service';
import { RandomBackgroundColorGeneratorDirective } from 'src/app/directive/random-backgroundcolor-generator.directive';
import { TextEllipsisDirective } from "src/app/directive/text-ellipsis.directive";
import { AgentsMarqueeComponent } from './components/agents-marquee/agents-marquee.component';
import { agents, agentsObject, callStatsObject, dashboardData, internalSocketConnection } from './real-time-dashboard-interface';
import { customWindow } from 'src/custom';
import { uiClientsConfig } from 'config';


@Component({
  selector: 'app-real-time-dashboard',
  standalone: true,
  imports: [AgentsMarqueeComponent, CommonModule, RandomBackgroundColorGeneratorDirective, TextEllipsisDirective],
  templateUrl: './real-time-dashboard.component.html',
  styleUrls: ['./real-time-dashboard.component.css']
})
export class RealTimeDashboardComponent implements OnInit {

  constructor(private cookieService: CookieService, public dashboardService: DashboardService) { }
  window: customWindow = window;
  internalSocketConnection: internalSocketConnection = {
    socketConnection: {} as Socket,
    isTryingReconnection: false,
    retryCount: 0,
    retryConnectionTimeout: 10000,
    maxRetryAttempts: 15
  };
  dashboardData: dashboardData = {
    agentData: {} as agentsObject,
    callStats: {} as callStatsObject
  }
  agentInMeeting: agents[] = [];
  agentInTraining: agents[] = [];
  agentInBreak: agents[] = [];
  agentInLunch: agents[] = [];
  agentInPersonal: agents[] = [];
  agentInAfterCall: agents[] = [];
  agentInInboundOnCall: agents[] = [];
  agentInOutboundOnCall: agents[] = [];
  agentInTakingCalls: agents[] = [];
  agentInMakingCalls: agents[] = [];
  agentInGettingReady: agents[] = [];
  oauthUserId: string = "";
  animationPaused: boolean = false;
  subDomain: string = window.location.host.split("-focus")[0];
  agents: agents[] = [];
  agentInOthers: any = [];

  setAgents(agentObject: agentsObject) {
    this.removeLoggedOutAgent('MEETING')
    this.removeLoggedOutAgent('TRAINING')
    this.removeLoggedOutAgent('BREAK')
    this.removeLoggedOutAgent('LUNCH')
    this.removeLoggedOutAgent('PERSONAL')
    this.removeLoggedOutAgent('AFTER_CALL')
    this.removeLoggedOutAgent('ON_CALL') //INBOUND
    this.removeLoggedOutAgent('ON_CALL_OUTBOUND') //OUTBOUND
    this.removeLoggedOutAgent('TWILIO_CALLS')
    this.removeLoggedOutAgent('MAKING_CALLS')
    this.removeLoggedOutAgent('GETTING_READY')
    this.removeLoggedOutAgent('OTHERS')

    this.agents = Object.keys(agentObject).map((innerData: string) => {
      return agentObject[innerData]
    })
    this.agents.forEach(agentData => {
      agentData = this.calculateTime(agentData);
      this.removeAgent(agentData, 'MEETING')
      this.removeAgent(agentData, 'TRAINING')
      this.removeAgent(agentData, 'BREAK')
      this.removeAgent(agentData, 'LUNCH')
      this.removeAgent(agentData, 'PERSONAL')
      this.removeAgent(agentData, 'AFTER_CALL')
      this.removeAgent(agentData, 'ON_CALL')       //INBOUND
      this.removeAgent(agentData, 'ON_CALL_OUTBOUND')  //OUTBOUND
      this.removeAgent(agentData, 'TWILIO_CALLS')
      this.removeAgent(agentData, 'MAKING_CALLS')
      this.removeAgent(agentData, 'GETTING_READY')
      this.removeAgent(agentData, 'OTHERS')

      // 'MEETING', 'TRAINING', 'BREAK', 'LUNCH', 'PERSONAL', 'GETTING_READY', 'TAKING_CALLS', 'TWILIO_CALLS', 'AFTER_CALL', 'ON_CALL' , 'ON_CALL_OUTBOUND'
      switch (agentData.userStatus) {
        case 'MEETING':
          const meetIndex: number = this.agentInMeeting.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (meetIndex !== -1) {
            for (let i = 0; i < this.agentInMeeting.length; i++) {
              if (this.agentInMeeting[i].oauthUserId === agentData.oauthUserId) {
                this.agentInMeeting[i].time = agentData.time;
              }

            }
          }
          else {
            this.agentInMeeting.push(agentData);
          }
          break;
        case 'TRAINING':
          const trainIndex: number = this.agentInTraining.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (trainIndex !== -1) {
            for (let i = 0; i < this.agentInTraining.length; i++) {
              if (this.agentInTraining[i].oauthUserId === agentData.oauthUserId) {
                this.agentInTraining[i].time = agentData.time;
              }

            }
          }
          else {
            this.agentInTraining.push(agentData);
          }
          break;
        case 'BREAK':
          const breakIndex: number = this.agentInBreak.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (breakIndex !== -1) {
            for (let i = 0; i < this.agentInBreak.length; i++) {
              if (this.agentInBreak[i].oauthUserId === agentData.oauthUserId) {
                this.agentInBreak[i].time = agentData.time;
              }

            }
          }
          else {
            this.agentInBreak.push(agentData);
          }
          break;
        case 'LUNCH':
          const lunchIndex: number = this.agentInLunch.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (lunchIndex !== -1) {
            for (let i = 0; i < this.agentInLunch.length; i++) {
              if (this.agentInLunch[i].oauthUserId === agentData.oauthUserId) {
                this.agentInLunch[i].time = agentData.time;
              }

            }
          }
          else {
            this.agentInLunch.push(agentData);
          }
          break;
        case 'PERSONAL':
          const personalIndex: number = this.agentInPersonal.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (personalIndex !== -1) {
            for (let i = 0; i < this.agentInPersonal.length; i++) {
              if (this.agentInPersonal[i].oauthUserId === agentData.oauthUserId) {
                this.agentInPersonal[i].time = agentData.time;
              }

            }
          }
          else {
            this.agentInPersonal.push(agentData);
          }
          break;
        case 'AFTER_CALL':
          const afterCallIndex: number = this.agentInAfterCall.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (afterCallIndex !== -1) {
            for (let i = 0; i < this.agentInAfterCall.length; i++) {
              if (this.agentInAfterCall[i].oauthUserId === agentData.oauthUserId) {
                this.agentInAfterCall[i].time = agentData.time;
              }

            }
          }
          else {
            this.agentInAfterCall.push(agentData);
          }
          break;
        case 'ON_CALL': //inBound
          const onCallIndex: number = this.agentInInboundOnCall.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (onCallIndex !== -1) {
            for (let i = 0; i < this.agentInInboundOnCall.length; i++) {
              if (this.agentInInboundOnCall[i].oauthUserId === agentData.oauthUserId) {
                this.agentInInboundOnCall[i].time = agentData.time;
              }

            }
          }
          else {
            this.agentInInboundOnCall.push(agentData);
          }
          break;
        case 'ON_CALL_OUTBOUND': //outBound
          const makingCallIndex: number = this.agentInOutboundOnCall.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (makingCallIndex !== -1) {
            for (let i = 0; i < this.agentInOutboundOnCall.length; i++) {
              if (this.agentInOutboundOnCall[i].oauthUserId === agentData.oauthUserId) {
                this.agentInOutboundOnCall[i].time = agentData.time;
              }
            }
          }
          else {
            this.agentInOutboundOnCall.push(agentData);
          }
          break;
        case 'TAKING_CALLS':
          const takingCallIndex: number = this.agentInTakingCalls.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (takingCallIndex !== -1) {
            for (let i = 0; i < this.agentInTakingCalls.length; i++) {
              if (this.agentInTakingCalls[i].oauthUserId === agentData.oauthUserId) {
                this.agentInTakingCalls[i].time = agentData.time;
              }

            }
          }
          else {
            this.agentInTakingCalls.push(agentData);
          }
          break;
        case 'MAKING_CALLS':
          const userMakingCallIndex: number = this.agentInMakingCalls.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (userMakingCallIndex !== -1) {
            for (let i = 0; i < this.agentInMakingCalls.length; i++) {
              if (this.agentInMakingCalls[i].oauthUserId === agentData.oauthUserId) {
                this.agentInMakingCalls[i].time = agentData.time;
              }

            }
          }
          else {
            this.agentInMakingCalls.push(agentData);
          }
          break;
        case 'GETTING_READY':
          const gettingReadyIndex: number = this.agentInGettingReady.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (gettingReadyIndex !== -1) {
            for (let i = 0; i < this.agentInGettingReady.length; i++) {
              if (this.agentInGettingReady[i].oauthUserId === agentData.oauthUserId) {
                this.agentInGettingReady[i].time = agentData.time;
              }

            }
          }
          else {
            this.agentInGettingReady.push(agentData);
          }
          break;
        case 'TWILIO_CALLS':
          const index: number = this.agentInTakingCalls.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (index !== -1) {
            for (let i = 0; i < this.agentInTakingCalls.length; i++) {
              if (this.agentInTakingCalls[i].oauthUserId === agentData.oauthUserId) {
                this.agentInTakingCalls[i].time = agentData.time;
              }
            }
          }
          else {
            this.agentInTakingCalls.push(agentData);
          }
          break;
        case 'RECEIVING_ASSISTANCE_OTHERS':
        case 'GIVING_ASSISTANCE_OTHERS':
        case 'TASKING_OTHERS':
          const otherIndex = this.agentInOthers.findIndex(obj => obj.oauthUserId === agentData.oauthUserId);
          if (otherIndex !== -1) {
            for (let i = 0; i < this.agentInOthers.length; i++) {
              if (this.agentInOthers[i].oauthUserId === agentData.oauthUserId) {
                this.agentInOthers[i].time = agentData.time;
              }
            }
          }
          else {
            this.agentInOthers.push(agentData);
          }
          break;
        //outBound pending
      }
    })
  }

  calculateTime(agentData: agents) {
    // for (let i = 0; i < array.length; i++) {
    const seconds: number = parseInt(agentData.age);
    const minutes: number = Math.floor(seconds / 60);
    const remainingSeconds: number = seconds % 60;

    const formattedMinutes: string = String(minutes).padStart(2, '0');
    const formattedSeconds: string = String(remainingSeconds).padStart(2, '0');

    const hours = Math.floor(minutes / 60);
    let timeAgo: string = "";
    if (hours > 0) {
      timeAgo = `+${hours} hour${hours > 1 ? "s" : ""}`;
    } else if (formattedMinutes > "0") {
      timeAgo = `${formattedMinutes}:${formattedSeconds}`;
    } else {
      timeAgo = `00:${seconds}`;
    }
    agentData.time = timeAgo;
    // }
    return agentData;
  }

  private createInternalMainSocketConnection(initiateConnection: boolean = true) {
    if (initiateConnection) {
      this.bindInternalSocketConnection();
    }
    else {
      this.internalSocketConnection?.socketConnection.disconnect();
    }
  }

  removeAgent(agentData: agents, type: string) {
    switch (type) {
      case 'MEETING':
        const meetIndex: number = this.agentInMeeting.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
        if (meetIndex !== -1)
          this.agentInMeeting.splice(meetIndex, 1);
        break;
      case 'TRAINING':
        const trainIndex: number = this.agentInTraining.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
        if (trainIndex !== -1) {
          this.agentInTraining.splice(trainIndex, 1);
        }
        break;
      case 'BREAK':
        const breakIndex: number = this.agentInBreak.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
        if (breakIndex !== -1) {
          this.agentInBreak.splice(breakIndex, 1);
        }
        break;
      case 'LUNCH':
        const lunchIndex: number = this.agentInLunch.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
        if (lunchIndex !== -1) {
          this.agentInLunch.splice(lunchIndex, 1)
        }
        break;
      case 'PERSONAL':
        const personalIndex: number = this.agentInPersonal.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
        if (personalIndex !== -1) {
          this.agentInPersonal.splice(personalIndex, 1)
        }
        break;
      case 'AFTER_CALL':
        const afterCallIndex: number = this.agentInAfterCall.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
        if (afterCallIndex !== -1) {
          this.agentInAfterCall.splice(afterCallIndex, 1)
        }
        break;
      case 'ON_CALL': //inBound
        const onCallIndex: number = this.agentInInboundOnCall.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
        if (onCallIndex !== -1) {
          this.agentInInboundOnCall.splice(onCallIndex, 1);
        }
        break;
      case 'ON_CALL_OUTBOUND': //outBound
        const makingCallIndex: number = this.agentInOutboundOnCall.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
        if (makingCallIndex !== -1) {
          this.agentInOutboundOnCall.splice(makingCallIndex, 1);
        }
        break;
      case 'MAKING_CALLS':
        const userMakingCallsIndex: number = this.agentInMakingCalls.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
        if (userMakingCallsIndex !== -1) {
          this.agentInMakingCalls.splice(userMakingCallsIndex, 1)
        }
        break;
      case 'GETTING_READY':
        const gettingReadyIndex: number = this.agentInGettingReady.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
        if (gettingReadyIndex !== -1) {
          this.agentInGettingReady.splice(gettingReadyIndex, 1)
        }
        break;
      // case 'TAKING_CALLS':
      //   const takingCallIndex = this.agentInTakingCalls.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
      //   if (takingCallIndex !== -1) {
      //     this.agentInTakingCalls.splice(takingCallIndex,1);
      //   } //outBound pending  
      //   break;
      case 'TWILIO_CALLS':
        const index: number = this.agentInTakingCalls.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
        if (index !== -1) {
          this.agentInTakingCalls.splice(index, 1);
        }
        break;
      case 'OTHERS':
        const otherIndex = this.agentInOthers.findIndex(obj => obj.oauthUserId === agentData.oauthUserId && obj.userStatus !== agentData.userStatus);
        if (otherIndex !== -1)
          this.agentInOthers.splice(otherIndex, 1);
        break;
    }
  }
  removeLoggedOutAgent(type: string) {
    switch (type) {
      case 'MEETING':
        for (let i = 0; i < this.agentInMeeting.length; i++) {
          let index: number = this.agents.findIndex(obj => obj.oauthUserId === this.agentInMeeting[i].oauthUserId);
          if (index === -1) {
            this.agentInMeeting.splice(i, 1);
          }
        }
        break;
      case 'TRAINING':
        for (let i = 0; i < this.agentInTraining.length; i++) {
          let index: number = this.agents.findIndex(obj => obj.oauthUserId === this.agentInTraining[i].oauthUserId);
          if (index === -1) {
            this.agentInTraining.splice(i, 1);
          }
        }
        break;
      case 'BREAK':
        for (let i = 0; i < this.agentInBreak.length; i++) {
          let index: number = this.agents.findIndex(obj => obj.oauthUserId === this.agentInBreak[i].oauthUserId);
          if (index === -1) {
            this.agentInBreak.splice(i, 1);
          }
        }
        break;
      case 'LUNCH':
        for (let i = 0; i < this.agentInLunch.length; i++) {
          let index: number = this.agents.findIndex(obj => obj.oauthUserId === this.agentInLunch[i].oauthUserId);
          if (index === -1) {
            this.agentInLunch.splice(i, 1);
          }
        }
        break;
      case 'PERSONAL':
        for (let i = 0; i < this.agentInPersonal.length; i++) {
          let index: number = this.agents.findIndex(obj => obj.oauthUserId === this.agentInPersonal[i].oauthUserId);
          if (index === -1) {
            this.agentInPersonal.splice(i, 1);
          }
        }
        break;
      case 'AFTER_CALL':
        for (let i = 0; i < this.agentInAfterCall.length; i++) {
          let index: number = this.agents.findIndex(obj => obj.oauthUserId === this.agentInAfterCall[i].oauthUserId);
          if (index === -1) {
            this.agentInAfterCall.splice(i, 1);
          }
        }
        break;
      case 'ON_CALL': //inBound
        for (let i = 0; i < this.agentInInboundOnCall.length; i++) {
          let index: number = this.agents.findIndex(obj => obj.oauthUserId === this.agentInInboundOnCall[i].oauthUserId);
          if (index === -1) {
            this.agentInInboundOnCall.splice(i, 1);
          }
        }
        break;
      case 'ON_CALL_OUTBOUND': //outBound
        for (let i = 0; i < this.agentInOutboundOnCall.length; i++) {
          let index: number = this.agents.findIndex(obj => obj.oauthUserId === this.agentInOutboundOnCall[i].oauthUserId);
          if (index === -1) {
            this.agentInOutboundOnCall.splice(i, 1);
          }
        }
        break;
      case 'TWILIO_CALLS':
        for (let i = 0; i < this.agentInTakingCalls.length; i++) {
          let index: number = this.agents.findIndex(obj => obj.oauthUserId === this.agentInTakingCalls[i].oauthUserId);
          if (index === -1) {
            this.agentInTakingCalls.splice(i, 1);
          }
        }
        break;
      case 'MAKING_CALLS':
        for (let i = 0; i < this.agentInMakingCalls.length; i++) {
          let index: number = this.agents.findIndex(obj => obj.oauthUserId === this.agentInMakingCalls[i].oauthUserId);
          if (index === -1) {
            this.agentInMakingCalls.splice(i, 1);
          }
        }
        break;
      case 'GETTING_READY':
        for (let i = 0; i < this.agentInGettingReady.length; i++) {
          let index: number = this.agents.findIndex(obj => obj.oauthUserId === this.agentInGettingReady[i].oauthUserId);
          if (index === -1) {
            this.agentInGettingReady.splice(i, 1);
          }
        }
        break;
      case 'OTHERS':
        for (let i = 0; i < this.agentInOthers.length; i++) {
          let index = this.agents.findIndex(obj => obj.oauthUserId === this.agentInOthers[i].oauthUserId);
          if (index === -1) {
            this.agentInOthers.splice(i, 1);
          }
        }
        break;
    }
  }

  private getAccessToken() {
    const subdomain: string = window.location.hostname.split('.')[0].split("-")[0];
    const cookies: string[] = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie: string = cookies[i].trim();

      if (cookie.includes(subdomain) && cookie.includes("access_token")) {
        let decodedCookieValue: string = (decodeURIComponent(cookie)).split("=")[1];
        const tokenObject = JSON.parse(decodedCookieValue);

        return tokenObject.access_token;
      }
    }

    return null;
  }

  private bindInternalSocketConnection() {
    const accessToken: string | null = this.getAccessToken();
    this.oauthUserId = this.cookieService.get(`${this.subDomain}_providerUserId`);
    this.internalSocketConnection.socketConnection = io(`https://${this.subDomain}-admin.${uiClientsConfig.reservedUrlDomain}`, {
      transports: ["websocket"],
      query: {
        id: this.oauthUserId,
        socketAccessToken: accessToken
      },
      reconnection: false
    });
    this.internalSocketConnection.socketConnection.on("connect", () => {
      console.log("Connected to Internal Socket.IO server", this.internalSocketConnection.socketConnection.id);
      this.internalSocketConnection.socketConnection.emit("connected");

      this.listenForEvent("call-monitoring-dashboard-metrics")
        .subscribe({
          next: (data: [agentsObject, callStatsObject]) => {
            this.dashboardData.agentData = data[0] ?? {} as agentsObject;
            this.dashboardData.callStats = data[1] ?? {} as callStatsObject;
            const seconds: number = this.dashboardData.callStats[0]?.avgWaitTime;
            const minutes: number = Math.floor(seconds / 60);
            const remainingSeconds: number = seconds % 60;

            const formattedMinutes = String(minutes);
            const formattedSeconds = String(remainingSeconds).padStart(2, '0');

            let timeAgo: string = "";
            if (minutes > 0) {
              timeAgo = `${formattedMinutes}.${formattedSeconds}`;
            } else {
              timeAgo = `0.${formattedSeconds}`;
            }
            if (this.dashboardData.callStats) { this.dashboardData.callStats["0"].avgWaitTime = parseFloat(timeAgo); }
            this.setAgents(this.dashboardData.agentData);
          },
          error: (error) => { console.log("An error occurred", error); }
        });
    });
    this.internalSocketConnection.socketConnection.on("connectOk", (data) => {
      console.log("connectOK", data)
      this.dashboardService.startCallDashboardMetricPooling();
    });

    this.internalSocketConnection.socketConnection.on("disconnect", () => {
      if (this.window["forceReconnect"]) {
        this.internalSocketErrorFunction();
      }
      console.log("Disconnected from Internal Socket.IO server");
    });

    this.internalSocketConnection.socketConnection.on("error", () => {
      this.window["forceReconnect"] = true;
      this.internalSocketErrorFunction();
    });

    this.internalSocketConnection.socketConnection.on("connect_error", () => {
      if (this.window["forceReconnect"]) {
        console.log("Connect Error, forcing re-try");
        this.internalSocketErrorFunction();
      }
    });
  }

  private listenForEvent(eventName: string): Observable<[agentsObject, callStatsObject]> {
    return new Observable((observer) => {
      this.internalSocketConnection.socketConnection.on(eventName, (data: [agentsObject, callStatsObject]) => {
        observer.next(data);
      });
    });
  }

  /**
   * 
   */
  private internalSocketErrorFunction() {
    console.log(`Trying to reconnect to internal socket.io, current retry count: ${this.internalSocketConnection.retryCount + 1}`);
    this.internalSocketConnection.isTryingReconnection = true;
    if (this.internalSocketConnection.isTryingReconnection && (this.internalSocketConnection.retryCount < this.internalSocketConnection.maxRetryAttempts)) {
      this.internalSocketConnection.retryCount++;
      this.internalSocketConnection.retryConnectionTimeout = this.internalSocketConnection.retryCount > 10 ?
        (this.internalSocketConnection.retryConnectionTimeout + this.internalSocketConnection.retryConnectionTimeout) : this.internalSocketConnection.retryConnectionTimeout;
      console.log(`Attempting reconnection for internal socket.io in next ${(this.internalSocketConnection.retryConnectionTimeout) / 1000} seconds, next retry count: ${this.internalSocketConnection.retryCount + 1}`);
      setTimeout(() => {
        this.bindInternalSocketConnection();
      }, this.internalSocketConnection.retryConnectionTimeout);
    } else {
      console.log(`Stopped internal socket.io connection try after ${this.internalSocketConnection.retryCount + 1} attempts.`)
    }
  }

  ngOnInit(): void {
    this.window["forceReconnect"] = true;
    this.dashboardService.startCallDashboardMetricPooling();
    window.localStorage.removeItem("currentPatientProfileId"); // for patient profile view
    this.createInternalMainSocketConnection(true);
    // this.createDummyData();
  }

  ngOnDestroy() {
    this.window["forceReconnect"] = false;
    this.createInternalMainSocketConnection(false);
  }

}