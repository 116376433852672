import { Component, ElementRef, Inject, OnInit, Optional, ViewChild, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { InboxService } from '../../inbox.service';
import { ToastrService } from 'ngx-toastr';
import { LocalServiceService } from 'src/app/services/local-service.service';
import { ApisService } from 'src/app/services/apis.service';
import { RandomBackgroundColorGeneratorDirective } from 'src/app/directive/random-backgroundcolor-generator.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CookieService } from 'ngx-cookie-service';
import { MatExpansionModule } from '@angular/material/expansion';
import { TwilioConnection } from 'src/app/twilio-connection/twilio-connection';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CamelCasePipePipe } from '../../../pipes/camel-case-pipe.pipe';
import { uiClientsConfig } from 'config';
import { showWorkers, availableWorkers, workerIds } from './conference-modal-interfaces';
import { interval, Subscription } from 'rxjs';
import { callerDetail, participantObject } from '../interface';
import { HttpErrorResponse } from '@angular/common/http';
export interface patientDetails {
  participantType: string,
  patientName: string;
  patientInitials: string,
  patientProfilePicture: string
}
export interface agentDetails {
  participantType: string,
  displayName: string,
  oauthUserId: string,
  profilePictureUrl: string;
  orgEntityId: string;
  agentInitials: string;
  isMuted: boolean;
}
@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, RandomBackgroundColorGeneratorDirective, MatAutocompleteModule, CamelCasePipePipe, MatRadioModule, MatFormFieldModule, MatInputModule, MatExpansionModule, ScrollingModule],
  selector: 'app-conference-modal',
  templateUrl: './conference-modal.component.html',
  styleUrls: ['./conference-modal.component.css']
})
export class ConferenceModalComponent implements OnInit {

  @ViewChild('ivrInputDialer') inputDialer: ElementRef = {} as ElementRef;;

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConferenceModalComponent>,
    public toastr: ToastrService, public inboxService: InboxService,
    public localService: LocalServiceService, public apiService: ApisService,
    private cookieService: CookieService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      isTransferButtonDisabled: boolean,
      orgEntityId: string
    },
    private twilioConnection: TwilioConnection,
  ) {
    this.customerUrlPrefix = window.location.hostname.split("-")[0];
  };
  patientOrgId: string = "";
  isDialing: boolean = false;
  patientName: string = "";
  toTwilioNumber: string = "";
  taskSid: string = "";
  dialNumber: string = "";
  workerSid: string = "";
  patientInitials: string = "";
  profilePicture: string = "";
  twilioWorkerName: string = "";
  allAvailableWorkers: availableWorkers[] = [];
  dialAgent: availableWorkers[] = [];
  oauthUserName: string = "";
  oauthUserprofilePictureUrl: string = "";
  oauthUserId: string = "";
  getParticipantsCount: number | null = null;
  isConferenceCallStarted: boolean = false;
  conferenceCallParticipant: boolean = false;
  workerActivity: string = '';
  availableWorkerInterval: ReturnType<typeof setInterval> | undefined;
  patientDetails: patientDetails = {
    participantType: 'customer',
    patientName: '',
    patientInitials: '',
    patientProfilePicture: '',
  };
  agentDetails: participantObject[] = [{
    participantType: '',
    displayName: '',
    oauthUserId: '',
    profilePictureUrl: '',
    orgEntityId: '',
    agentInitials: '',
    isMuted: false,
    isHold: false
  }];
  showDropDown: boolean = false;
  patientPhoneNumber: string | undefined = "";
  imgUrlPrefix = "https://" + uiClientsConfig.customerUrlPrefix + "-admin." + uiClientsConfig.reservedUrlDomain;
  defaultUserImgUrl = "img/default-user.png";
  providerQueueList: participantObject[] = []
  associatedPatientName: string = '';
  callStatus: string = '';
  isRequestPending: boolean = false;
  externalAgentNumber: string = "";
  usertype: string = "internal";
  customerUrlPrefix: string = "";
  conferenceRequestSent: boolean = false;
  //dialer for conference
  openMainPanel: boolean = false;
  externalIvrInputNumber: string = "";
  // flag to check if user is onCall
  isExternalAgentOnCall: boolean = false;
  showDialer: boolean = false;
  isDialerDisabled: boolean = true;
  isCallingExternalAgent: boolean = false;
  externalQueueList: string[] = [];

  @HostListener('document:mousedown', ['$event.target'])

  onClickedOutside(target: HTMLElement) {
    if (target.classList.contains('cdk-overlay-backdrop')) {
      if (this.workerIds && this.workerIds.length) {
        this.dialogRef.close({ isTransferButtonDisabled: this.conferenceRequestSent, timeLeft: this.workerIds[this.workerIds.length - 1].time });
      }
      else if (this.inboxService.getCurrentDial() && this.inboxService.getCurrentDial().length) {
        this.dialogRef.close({ isTransferButtonDisabled: this.conferenceRequestSent, timeLeft: this.inboxService.getCurrentDial()[this.inboxService.getCurrentDial().length - 1].time })
      }
      else {
        this.dialogRef.close({ isTransferButtonDisabled: this.conferenceRequestSent, timeLeft: 900000 })
      }
    }
  }
  closeModal() {
    if (this.availableWorkerInterval) {
      clearInterval(this.availableWorkerInterval);
    }
    this.availableWorkerInterval = undefined;
    if (this.workerIds && this.workerIds.length) {
      this.dialogRef.close({ isTransferButtonDisabled: this.conferenceRequestSent, timeLeft: this.workerIds[this.workerIds.length - 1].time });
    }
    else if (this.inboxService.getCurrentDial() && this.inboxService.getCurrentDial().length) {
      this.dialogRef.close({ isTransferButtonDisabled: this.conferenceRequestSent, timeLeft: this.inboxService.getCurrentDial()[this.inboxService.getCurrentDial().length - 1].time })
    }
    else {
      this.dialogRef.close({ isTransferButtonDisabled: this.conferenceRequestSent, timeLeft: 900000 })
    }
  }
  showWorker() {
    this.showDropDown = true;
    this.allAvailableWorkers = [];
    this.inboxService.getAvailableWorkers().subscribe({
      next: (result: showWorkers) => {
        this.allAvailableWorkers = result.workersResult;
        for (let i = 0; i < result.workersResult.length; i++) {
          this.allAvailableWorkers[i].activity = this.allAvailableWorkers[i].activity.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2');
        }
      },
      error: (error: HttpErrorResponse) => {
        let message = (error.error ? error.error.message ? error.error.message : "Something went wrong" : "Something went wrong");
        this.toastr.warning(message);
      }
    });
    this.availableWorkerInterval = setInterval(() => {
      let previousWorkers = this.allAvailableWorkers;
      this.inboxService.getAvailableWorkers().subscribe({
        next: (result: showWorkers) => {
          if (result.workersResult && (result.workersResult.length === previousWorkers.length)) {
            result.workersResult.forEach(update => {
              const workerObjToUpdate = previousWorkers.find(obj => obj.oauthUserId === update.oauthUserId);
              if (workerObjToUpdate) {
                workerObjToUpdate.activity = update.activity.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2');
              }
            });
          } else {
            this.allAvailableWorkers = result.workersResult;
            for (let i = 0; i < result.workersResult.length; i++) {
              this.allAvailableWorkers[i].activity = this.allAvailableWorkers[i].activity.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2')
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          let message = (error.error ? error.error.message ? error.error.message : "Something went wrong" : "Something went wrong");
          this.toastr.warning(message);
        }
      })
    }, 7000);
  }
  deleteDialAgent(workerInfo: availableWorkers) {
    let workerInfoArray: availableWorkers[] = [];
    workerInfoArray.push(workerInfo)
    if (this.dialAgent.length && workerInfoArray.length) {
      const currentDialAgent = this.dialAgent.filter(obj => !workerInfoArray.some(item => item.oauthUserId === obj.oauthUserId))
      this.dialAgent = currentDialAgent;
    }
  }
  workerIds: workerIds[] = [];
  selectOption(worker: availableWorkers) {
    if (worker.activity === "Available") {
      if (!this.workerIds.some(obj => obj.id === worker.workerId)) {
        if (this.dialAgent.findIndex(obj => obj.oauthUserId === worker.oauthUserId) === -1) {
          this.workerIds.push({ id: worker.workerId, time: Date.now(), oauthId: worker.oauthUserId });
          this.dialAgent.push(worker);
          setTimeout(() => {
            this.deleteDialAgent(worker);
          }, 90000);
          this.inboxService.setCurrentDial(Date.now(), worker);
          this.showDropDown = false;
          this.workerSid = worker.workerId;
          this.twilioWorkerName = worker.displayName;
          this.conferenceCallParticipant = true;
          this.inboxService.conferenceCall(this.workerSid, this.taskSid, '', this.patientOrgId).subscribe((result) => {
            this.isConferenceCallStarted = true;
            this.toastr.success("Conference Request sent successfully");
            this.twilioWorkerName = "";
            this.conferenceRequestSent = true;
          }, (error) => {
            let message = (error.error ? error.error.message ? error.error.message : "Something went wrong" : "Something went wrong");
            if (message.includes("A call is already assigned to this agent")) {
              let workerPresent: availableWorkers[] = [];
              workerPresent.push(worker)
              const isAgentBusy = this.dialAgent.filter(obj => !workerPresent.some(item => item.oauthUserId === obj.oauthUserId))
              this.dialAgent = isAgentBusy;
              this.inboxService.isDialPick(workerPresent);
            }
            this.toastr.warning(message);
          })
        }
        else {
          this.toastr.warning("Request is already pending. Please wait for the previous request to be processed.");
        }

      } else {
        let currentWorkerDetail = this.workerIds.find(obj => obj.id === worker.workerId);
        if (currentWorkerDetail) {
          // Reset the flag and clear the warning message after 90 seconds
          if (Date.now() - currentWorkerDetail.time > 90000) {
            this.workerIds = this.workerIds.filter(obj => obj.id !== worker.workerId);
            this.selectOption(worker);
          } else {
            this.toastr.warning("Request is already pending. Please wait for the previous request to be processed.");
          }
        }
      }
    } else {
      this.showDropDown = true;
      this.isConferenceCallStarted = false;
      this.twilioWorkerName = "";
      this.toastr.warning("Agent is unavailable");
    }
  }
  showDropdown() {
    this.showDropDown = false
  }
  searchFilterObject(prop: availableWorkers[], val: string) {
    return prop.filter((obj: availableWorkers) => obj.displayName.toLowerCase().includes(val.toLowerCase()));
  }
  changeUsertype(event: any) {
    // this.isDisabled = true;
    this.externalAgentNumber = "";
    this.dialNumber = "";
    this.usertype = event.value;
    // this.selectWorkerDropdown?.options.forEach((data: MatOption) => data.deselect());
  }
  checkIfNumber(event: any) {
    /**check the value of the keyPress to make sure it's a num. which from 48-57 are 0-9 */
    if ((event.which !== 8 && event.which < 48) || event.which > 57) {
      event.preventDefault();
    }
  }
  formatNumber() {
    var filterPhoneNumber = function (phoneNumber: string) {
      if (!phoneNumber) { return ''; }
      var value = phoneNumber.toString().trim().replace(/^\+/, '');
      if (value.match(/[^0-9]/)) {
        return phoneNumber;
      }
      var number = value;
      if (number) {
        if (number.length > 6) {
          number = number.slice(0, 3) + '-' + number.slice(3, 6) + '-' + number.slice(6, 10);
        }
        else if (number.length > 3) {
          number = number.slice(0, 3) + '-' + number.slice(3, 6);
        } return ('+1 ' + number).trim();
      }
      else { return '+1 ' + number; }
    };
    this.externalAgentNumber = this.externalAgentNumber.replace(/-/g, "");
    if (this.externalAgentNumber.includes('+'))
      this.externalAgentNumber = this.externalAgentNumber.slice(3);
    this.externalAgentNumber = filterPhoneNumber(this.externalAgentNumber);
    this.dialNumber = this.externalAgentNumber.replace(/[^\d+]/g, "");
    if (this.externalAgentNumber.length == 15) {
      // this.isDisabled = false;
    }
  }

  //for Unknown
  addToConference(dialNumber: string) {
    this.showDialer = true;
    this.isDialerDisabled = true;
    dialNumber = "+" + dialNumber.split(/\D/).join("");
    const numberExists = this.providerQueueList.some((item) => item.displayName === dialNumber);
    if (numberExists) {
      this.toastr.warning("Same user with this number exists on the call");
    }
    else {
      this.holdStatus = true;
      this.localService.changeHoldStatus(this.holdStatus);
      this.isCallingExternalAgent = true;
      this.inboxService.conferenceCall('', this.taskSid, dialNumber, this.patientOrgId).subscribe((result) => {
        this.externalQueueList.push(dialNumber);
        this.externalAgentNumber = '';
        this.dialNumber = '';
        this.toastr.success("Conference Request sent successfully");
      }, (error) => {
        let message = (error.error ? error.error.message ? error.error.message : "Something went wrong" : "Something went wrong");
        this.toastr.warning(message);
      });
    }
  }

  selectOptionByEnter() {
    const selectedOption = this.allAvailableWorkers.find(worker => worker.displayName === this.twilioWorkerName);
    if (selectedOption) {
      this.selectOption(selectedOption);
    }
  }
  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData('text/plain');

    if (pastedText) {
      const isNumeric = /^\d+$/.test(pastedText); // Check if pasted text is numeric

      if (!isNumeric) {
        event.preventDefault(); // Prevent the paste action
      }
    }
  }

  openDialer() {
    this.openMainPanel = !this.openMainPanel;
  }

  clickingOnDialer(num: string) {
    this.twilioConnection.sendDigits(num);
    this.externalIvrInputNumber = this.externalIvrInputNumber + num;
    setTimeout(() => {                                  // always keeps input text right scrolled
      this.inputDialer.nativeElement.scrollLeft = this.inputDialer.nativeElement.scrollWidth;
    }, 0);
  }

  holdStatus: boolean = false;
  callHoldStatus() {
    this.holdStatus = !this.holdStatus;
    this.localService.changeHoldStatus(this.holdStatus);
  }

  activityTrackBy(index: number, activity: any) {
    return activity.id;
  }

  ngOnInit(): void {
    // this.patientOrgId = this.data?.orgEntityId;
    this.localService.getAssociatedPatientOrgId().subscribe((result: string) => {
      this.patientOrgId = result;
    })
    this.showWorker();
    var agents: string | null = window.localStorage.getItem("agentsInCall");
    var providers: string | null = window.localStorage.getItem('providerList');
    if (agents) {
      this.agentDetails = JSON.parse(agents);
    }
    if (providers) {
      this.providerQueueList = JSON.parse(providers);
    }
    this.taskSid = this.inboxService.getTaskSid();
    this.localService.currentAssociatedPatientDetail.subscribe((result: callerDetail) => {
      if (result) {
        if (result.patientName === "" || result.patientName === 'UNKNOWN_PATIENT' || result.patientName === 'Unknown Patient' || result.patientName === 'Unknown_Patient' || result.patientName === 'unknown patient') { this.patientDetails.patientName = ''; }
        else { this.patientDetails.patientName = result.patientName?.replaceAll("_", " "); }
        this.patientDetails.patientProfilePicture = result.profilePictureUrl;
        this.patientDetails.patientInitials = result.patientInitials;
      }
    })
    this.inboxService.currentNumber.subscribe((result: string | undefined) => {
      this.patientPhoneNumber = result?.replace(/^(\+?1)?(\d{3})(\d{3})(\d{4})$/, "+1 $2-$3-$4");
    });

    this.localService.participantObj.subscribe((result: participantObject[] | undefined) => {
      this.agentDetails = [];
      this.providerQueueList = [];
      if (result.length <= 1) {
        this.conferenceRequestSent = false;
      }
      if (this.dialAgent.length && result) {
        const dialList = this.dialAgent.filter(obj => !result.some(item => item.oauthUserId === obj.oauthUserId));
        this.dialAgent = dialList;
      }
      if (result.length) {
        this.inboxService.isDialPick(result);
        const isAgentPicked = this.workerIds.filter(obj => !result.some(item => item.oauthUserId === obj.oauthId));
        this.workerIds = isAgentPicked;
        let providerOauthUserId = this.cookieService.get(this.customerUrlPrefix + "_providerUserId");
        this.agentDetails = result;
        this.externalQueueList = this.externalQueueList.filter((str: string) => !result.some((obj: participantObject) => obj.displayName === str));
        this.providerQueueList = result.filter(obj => obj.oauthUserId !== providerOauthUserId);
        if (this.providerQueueList.some((item) => (item.orgEntityId === "" || item.orgEntityId === null || item.orgEntityId == undefined))) {
          this.showDialer = true;
          this.isDialerDisabled = false;
          this.isCallingExternalAgent = false;
        }
        else {
          this.showDialer = false;
          this.isDialerDisabled = true;
        }
        window.localStorage.setItem("agentsInCall", JSON.stringify(this.agentDetails));
        window.localStorage.setItem('providerList', JSON.stringify(this.providerQueueList));
        this.conferenceCallParticipant = true;
      }
    }, (error) => {
      let message = (error.error ? error.error.message ? error.error.message : "Something went wrong" : "Something went wrong");
      this.toastr.warning(message);
    });

    if (this.inboxService.getCurrentDial() && this.inboxService.getCurrentDial().length) {
      for (let i = 0; i < this.inboxService.getCurrentDial().length; i++) {
        if (Date.now() - this.inboxService.getCurrentDial()[i].time < 90000) {
          this.dialAgent.push(this.inboxService.getCurrentDial()[i].worker)
          var remainTime = Date.now() - this.inboxService.getCurrentDial()[i].time
          setTimeout(() => {
            this.deleteDialAgent(this.inboxService.getCurrentDial()[i].worker);
          }, 90000 - remainTime);
        }
      }
    }
    if (this.agentDetails && this.dialAgent && this.dialAgent.length && this.agentDetails.length) {
      const newDialList = this.dialAgent.filter(obj => !this.agentDetails.some(item => item.oauthUserId === obj.oauthUserId));
      this.dialAgent = newDialList;
    }

    this.localService.isCallLeaveOrHangup.subscribe((result: string) => {
      if (result === "RESERVATION_ENDED") {
        this.closeModal();
      }
    });

    this.localService.currentAssociatedPatientDetail.subscribe((result: callerDetail) => {
      if (result && result.patientName != "") {
        this.associatedPatientName = result.patientName?.replaceAll("_", " ");
        this.patientDetails.patientProfilePicture = result.profilePictureUrl;
        this.patientDetails.patientInitials = result.patientInitials;
      }
    })

    // to check if call hold status is changed by call slideup popover component
    this.localService.isCallOnHoldUI.subscribe((result: boolean) => {
      this.holdStatus = result;
    });
  }

}
