<div class="mainModal" style="overflow-y: auto;overflow-x: hidden;">
  <div class="modal-header" style="padding: 15px 30px; display: block">
    <div class="row">
      <div class="col-xs-10 col-sm-10 col-md-10">
        <h3 class="modal-title mb-0">
          <img src="../../assets/images/Advance Member search.svg" loading="lazy" style="width: 5%" />&nbsp;&nbsp;
          <span class="h3 font-thin" style="color: #58666e; font-size: 22px">Search Patient</span>
        </h3>
      </div>
      <div class="col-xs-2 col-sm-2 col-md-2">
        <button style="
              border: none;
              outline: none;
              float: right;
              background: transparent;
            " (click)="closeModal()">
          <img src="../../assets/images/close.svg" loading="lazy" style="
                color: white !important;
                border-radius: 4px;
                width: 28px;
                margin-top: 5px;
              " />
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div style="padding: 0px 25px;width: 100%; overflow-x: auto; overflow-y: unset !important;">
      <table class="table  table-borderless ">
        <thead class=" w-100" style="border: 4px solid #F6F8F8;">
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th style="width: 26%;">Date of Birth</th>
            <th>Phone Number</th>
            <th>Email Id</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr>
            <td>
              <input tabindex="1" class="input-wrapper" type="text" class="input" placeholder="First Name"
                [(ngModel)]="unknownPatientSearchObj.firstName" disallowSpaces>
            </td>
            <td>
              <input tabindex="1" class="input-wrapper" type="text" class="input" placeholder="Last Name"
                [(ngModel)]="unknownPatientSearchObj.lastName" disallowSpaces>
            </td>
            <td>
              <div class="form-group year" style="margin-bottom: 10px; margin-top: 10px;">
                <div class="input-group col-xs-12" style="display:flex;width: 100%;">
                  <mat-form-field appearance="fill" style="width:86px;">
                    <input type="text" class="autocomplete-input" placeholder="Month" matInput [matAutocomplete]="month"
                      style="caret-color: transparent; border: none; height: unset;padding-left: 0px !important;"
                      [(ngModel)]="dateOfBirthObject.dobMonth" (ngModelChange)="clearSelection()"
                      onkeydown="return false;">
                    <i class="fa fa-caret-down align-arrow-right"></i>
                    <mat-autocomplete #month="matAutocomplete" class="min-width-unset"
                      [displayWith]="monthName.bind(this)">
                      <mat-option *ngFor="let item of months" [value]="item.id"
                        style="font-size: 13px;padding: 0px 5px !important;" (click)="setDays()">
                        {{item.displayName}}
                      </mat-option>
                    </mat-autocomplete>

                    <!-- <mat-select placeholder="Month" tabindex="1" [(ngModel)]="dateOfBirthObject.dobMonth"
                    (selectionChange)="setDays()" style="font-size: 13px;color:red">
                    <mat-option *ngFor="let item of months" [value]=" item.id" style="width:92px">
                      {{item.displayName}}
                    </mat-option>
                  </mat-select> -->
                  </mat-form-field>
                  <mat-form-field appearance="fill" style="width:80px">
                    <input type="text" class="autocomplete-input" placeholder="Day" matInput [matAutocomplete]="day"
                      style="caret-color: transparent; border: none; height: unset;"
                      [(ngModel)]="dateOfBirthObject.dobDay" onkeydown="return false;">
                    <i class="fa fa-caret-down align-arrow-right"></i>
                    <mat-autocomplete #day="matAutocomplete" class="min-width-unset">
                      <mat-option *ngFor="let day of days" [value]="day" style="font-size: 13px;">
                        {{day}}
                      </mat-option>
                    </mat-autocomplete>

                    <!-- <mat-select placeholder="Day" tabindex="1" [(ngModel)]="dateOfBirthObject.dobDay"
                    style="font-size: 13px; ">
                    <mat-option *ngFor="let day of days" [value]="day" style="width:97px !important">
                      {{day}}
                    </mat-option>
                  </mat-select> -->
                  </mat-form-field>
                  <input style="width:64px;font-size: 13px;" id="year" type="text" tabindex="1" maxlength="4"
                    class="form-control virtual-care text-center" placeholder="Year"
                    [(ngModel)]="dateOfBirthObject.dobYear" (keypress)="checkIfNumber($event)" />
                </div>
              </div>
            </td>
            <td>
              <input tabindex="1" class="input-wrapper input" type="text" placeholder="Phone Number"
                (keyup)="formatNumber()" (paste)="onPaste($event)" (keypress)="checkIfNumber($event)"
                [(ngModel)]="formattedDialNumber" maxlength="15" disallowSpaces>
            </td>
            <td>
              <input tabindex="1" class="input-wrapper" type="text" class="input" placeholder="Email ID"
                [(ngModel)]="unknownPatientSearchObj.emailAddress" disallowSpaces>
            </td>
            <td>
              <button style="background-color: #1FBD75;padding: 6px 10px;border: 1px solid #dee5e7;"
                (click)="searchUnknownPatient(1)">
                <span style="color: #fff">Search Member</span>
              </button>
            </td>
          </tr>
      </table>
    </div>
    <div class="row" style="border-bottom: 1px dashed #d9d9d6"></div>
    <div
      style=" margin-top:10px;padding: 0px 25px;height: 250px;width: 100%; overflow-x: auto; overflow-y: unset !important;">
      <h3 style="color:#676d74">Search Result</h3>
      <table class="table  table-borderless searched-patients">
        <thead class=" w-100" style="border: 4px solid #F6F8F8">
          <tr style=" padding: 15px 0!important;">
            <th style="width: 5%;"></th>
            <th style="width: 8%;"></th>
            <th>Name</th>
            <th>Patient ID</th>
            <th>Gender</th>
            <th>Date of Birth</th>
            <th>Email ID</th>
            <th>Patient Phone</th>
            <th>Guarantor Number</th>
          </tr>
        </thead>
        <tbody class="">
          <tr style="border:none !important;background:#fff;" *ngIf="isLoading">
            <td colspan="10" style="text-align: center;padding-top:65px;">
              <img src="../../../../../../assets/loader/Loader2.gif" height="65" width="65" alt="" loading="lazy">
            </td>
          </tr>
          <tr style="border:none !important;background:#fff; " *ngIf="emptySearch && !isLoading && !noData">
            <td colspan="10" style="text-align: center;padding-top: 90px;color: #C5D1D4;">
              Search results will be displayed here
            </td>
          </tr>
          <tr style="border:none !important;background:#fff;" *ngIf="!isLoading && noData">
            <td colspan="10" style="text-align: center;padding-top: 90px;color: #C5D1D4;">
              No Results Found
            </td>
          </tr>
          <ng-container *ngIf="!emptySearch && !isLoading">
            <tr *ngFor="let patient of patientSearchArrayCopy"
              style="border-bottom: 2px dashed #e8e8e8; padding: 0px 0!important;">
              <td style="padding-left: 15px;">
                <mat-radio-button class="searched-patient-radio" value="{{patient.patientId}}" name="radio"
                  (change)="onRadioSelection($event)"></mat-radio-button>
              </td>
              <td>
                <img *ngIf="patient.profilePictureUrl !== defaultUserImgUrl" [src]="patient.profilePictureUrl"
                  style="width: 32px; height:32px; border-radius: 50%;margin-right:10px;" alt="" />

                <span appRandomBackgroundColorGenerator
                  class="initials d-flex justify-content-center align-items-center" style="width:32px; height:32px;"
                  *ngIf="patient.profilePictureUrl === defaultUserImgUrl || patient.profilePictureUrl===nullUrl ">{{patient.initials}}</span>
              </td>
              <td>{{patient.firstName}} {{patient.lastName}}</td>
              <td>{{patient.externalId}}</td>
              <td>{{patient.gender}}</td>
              <td>{{patient.dateOfBirth}}</td>
              <td>{{patient.emailAddress}}</td>
              <td>{{patient.cellPhone}}</td>
              <td>{{patient.guarantorPhoneNumber}}</td>
            </tr>
          </ng-container>
      </table>
    </div>
    <div>
      <div class="row mx-0 justify-content-end" style="margin:5px 0;">
        <pagination-template class="paginationSty" #p="paginationApi" [id]="config.id" *ngIf="totalRecords>limit"
          (pageChange)="config.currentPage = $event">
          <div style="color: #aaabac;">
            <p style="text-align: right; margin-top: 0px;" *ngIf="config.currentPage!==totalPages">Showing
              {{((config.currentPage-1)*limit)+1}} to {{config.currentPage*limit}} of {{totalRecords}} entries</p>
            <p style="text-align: right; margin-top: 0px;" *ngIf="config.currentPage===totalPages">Showing
              {{((config.currentPage-1)*limit)+1}} to {{totalRecords}} of {{totalRecords}} entries</p>
          </div>
          <ul class="custom-pagination float-right">
            <li class="paginator-nav-btns" style="border-bottom-left-radius: 5px;border-top-left-radius: 5px;"
              [ngClass]="{'disableNav': config.currentPage===1}" (click)="getPagination(1)">
              <a> <i class="fa fa-angle-double-left" style="font-weight: 900;"></i> </a>
            </li>
            <li class="paginator-nav-btns" [ngClass]="{'disableNav': config.currentPage===1}"
              (click)="getPagination(config.currentPage-1)">
              <a> <i class="fa fa-angle-left bold" style="font-weight: 900;"></i> </a>
            </li>
            <ul *ngFor="let page of pageObject">
              <li class="paginator-nav-btns" [ngClass]="{'activePage': page.selected}"
                (click)="getPagination(page.pageNum)"
                *ngIf="config.currentPage===page.pageNum+1||config.currentPage===page.pageNum||config.currentPage===page.pageNum-1">
                <a>{{page.pageNum}}</a>
              </li>
            </ul>

            <li class="paginator-nav-btns" [ngClass]="{'disableNav': config.currentPage===totalPages}"
              (click)="getPagination(config.currentPage+1)">
              <a> <i class="fa fa-angle-right" style="font-weight: 900;"></i> </a>
            </li>
            <li class="paginator-nav-btns" style="border-bottom-right-radius: 5px;border-top-right-radius: 5px;"
              [ngClass]="{'disableNav': config.currentPage===totalPages}" (click)="getPagination(totalPages)">
              <a> <i class="fa fa-angle-double-right" style="font-weight: 900;"></i> </a>
            </li>
          </ul>
        </pagination-template>
      </div>
    </div>

  </div>

  <div style="background-color: rgb(239 243 244 ); padding: 15px 20px; display: flex; justify-content: flex-end;">
    <button style="background-color: rgb(28 70 79);padding: 6px 15px;border: 1px solid #dee5e7;"
      *ngIf="allPermission.InboxFocusCallTabCreatePatientButton && displayCreatePatient" (click)="createPatient()">
      <span style="color: #fff">Create New Patient</span>
    </button>
    <button style="background-color: rgb(28 70 79);padding: 6px 15px;margin-left:10px;border: 1px solid #dee5e7;"
      (click)="asUnknownPatient()">
      <span style="color: #fff">Continue as a unknown patient</span>
    </button>
    <button
      style="background-color:var(--focus-theme-green);margin-right: 30px;margin-left: 10px;padding: 6px 15px;border: 1px solid #dee5e7;"
      *ngIf="allPermission.InboxFocusCallTabAssociateButton" (click)="associateMember()"
      [disabled]="isAssociateDisabled">
      <span style="color: #fff">Switch Patient</span>
    </button>
  </div>

  <div class="col-sm-12 modal-footer ">
    <button style="
          background-color: #ffffff;
          margin-right: 30px;
          margin-left: 5%;
          width: 90.1px;
          height: 35px;
          border: 1px solid #dee5e7;
        " (click)="closeModal()">
      <span style="color: #58666e">Cancel</span>
    </button>
  </div>
</div>