import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { checkExternalIdExistsPayload } from './create-new-patient-interfaces';
import { Observable, firstValueFrom  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateNewPatientModalService {
  token:string="";
  headers: HttpHeaders ;
  orgEntityApiPath = "/api/v1/platform/org-entity";
  userLookUpApiPath = "/api/v1/platform/oauth-user-lookup";
  createNewPatientApiPath = "/api/v1/platform/patient-details/create-patient"
  constructor(private http: HttpClient) {
    this.token = JSON.stringify(localStorage.getItem('access_token'));
    if (this.token) {
      this.token = "Bearer " + this.token.slice(1, -1);
    }
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json;charset=utf-8')
      .set('Authorization', this.token);
  }
  checkExternalIdAlreadyExistsOrNot(incomingObject: any) {
    return firstValueFrom(this.http.post(this.orgEntityApiPath + "/checkExternalIdExistsOrNot", incomingObject)
      .pipe(responseData => {
        return responseData;
      }))
  }
  createNewPatient(orgEntityData: any) {
    let header = new HttpHeaders().set(
      'Content-Type', 'application/json;charset=utf-8'
    );
    return firstValueFrom(this.http.post(this.createNewPatientApiPath, orgEntityData, { headers: header })
      .pipe(responseData => {
        return responseData;
      }))
  }

}
