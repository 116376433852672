import { Component, Inject, OnInit, Optional } from '@angular/core';
import { CreateNewPatientModalService } from './create-new-patient-modal.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { DisallowSpacesDirective } from '../../../../directive/disallow-spaces.directive';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { checkExternalIdExistsOrNot, checkExternalIdExistsPayload, createNewPatient } from './create-new-patient-interfaces';
export interface phoneNumbers {
  phoneNumber: string,
  type: string
}
export interface emailAddresses {
  email: string,
  type: string
}
export interface patientInfo {
  isIndividual: boolean,
  type: string,
  displayName: string,
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  birthGender: string,
  externalId: string,
  phoneNumbers: phoneNumbers[],
  emailAddresses: emailAddresses[],
}
export interface patientInformationObject {
  patientInfo: patientInfo;
}
export interface DateOfBirthObject {
  dateOfBirth: string,
  dobMonth: number | null,
  dobYear: string,
  dobDay: number | null,
}
@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ToastrModule, MatSelectModule, DisallowSpacesDirective, MatInputModule, MatAutocompleteModule],
  selector: 'app-create-new-patient-modal',
  templateUrl: './create-new-patient-modal.component.html',
  styleUrls: ['./create-new-patient-modal.component.css']
})
export class CreateNewPatientModalComponent implements OnInit {
  genderArr: string[] = ["Male", "Female"];
  __temporaryPassword: number | null = null;
  isValid: boolean = true;
  emailRegex: RegExp = /^[\S]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  nameRegex: RegExp = /^[A-Za-z0-9 ]+$/;
  phoneRegex: RegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
  deformatPhoneRegex: RegExp = /[^A-Za-z0-9]+/g;
  tempPhone: string = '';
  email: string = '';

  checkExternalIdExistsOrNot: checkExternalIdExistsOrNot = {
    data: {
      isSameOrgEntity: false,
      isExternalIdAlreadyPresent: false,
      newOrgEntityId: '',
      isNew: false
    },
    isActionSuccess: false
  }
  createNewPatientResult: createNewPatient = {
    data: {
      isIndividual: false,
      type: "",
      displayName: "",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      birthGender: "",
      externalId: "",
      phoneNumbers: [
        {
          phoneId: "",
          phoneNumber: "",
          isPrimary: false,
          type: "",
          isReserved: false,
          isVoiceNumber: false,
          isSmsNumber: false
        }
      ],
      emailAddresses: [
        {
          emailId: "",
          email: "",
          type: "",
          isPrimary: false,
          isReserved: false
        }
      ],
      orgEntityId: ""
    },
    isAlreadyPresent: false
  }
  patientInformationObject: patientInformationObject = {
    patientInfo: {
      isIndividual: true,
      type: "PATIENT",
      displayName: "",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      birthGender: "",
      externalId: "",
      phoneNumbers: [
        {
          phoneNumber: "",
          type: "CELL"
        }
      ],
      emailAddresses: [
        {
          email: "",
          type: "HOME"
        }
      ]
    },

  }
  dateOfBirthObject: DateOfBirthObject = {
    dateOfBirth: '',
    dobMonth: null,
    dobYear: '',
    dobDay: null,
  }
  constructor(
    public createNewPatientService: CreateNewPatientModalService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<CreateNewPatientModalService>,
    @Inject(MAT_DIALOG_DATA) private data: {
      showBackToSearch: boolean,
    },
  ) { }
  day: string = '';
  days: string[] = [];
  months = [
    {
      id: '01',
      displayName: 'January',
      maxDays: 31,
    },
    {
      id: '02',
      displayName: 'February',
      maxDays: 29,
    },
    {
      id: '03',
      displayName: 'March',
      maxDays: 31,
    },
    {
      id: '04',
      displayName: 'April',
      maxDays: 30,
    },
    {
      id: '05',
      displayName: 'May',
      maxDays: 31,
    },
    {
      id: '06',
      displayName: 'June',
      maxDays: 30,
    },
    {
      id: '07',
      displayName: 'July',
      maxDays: 31,
    },
    {
      id: '08',
      displayName: 'August',
      maxDays: 31,
    },
    {
      id: '09',
      displayName: 'September',
      maxDays: 30,
    },
    {
      id: '10',
      displayName: 'October',
      maxDays: 31,
    },
    {
      id: '11',
      displayName: 'November',
      maxDays: 30,
    },
    {
      id: '12',
      displayName: 'December',
      maxDays: 31,
    },
  ];

  setDays() {
    for (let j = 0; j < this.months.length; j++) {
      if (this.months[j].id == this.dateOfBirthObject.dobMonth?.toString()) {
        this.days = []; // Reset days array before adding new days
        for (let i = 1; i <= this.months[j].maxDays; i++) {
          if (i < 10) {
            this.day = "0" + i;
            this.days.push(this.day.toString());
          }
          else {
            this.days.push(i.toString());
          }
        }
      }
    }
  }
  checkNumber(event: any) {
    /**check the value of the keyPress to make sure it's a num. which from 48-57 are 0-9 */
    if ((event.which !== 8 && event.which < 48) || event.which > 57) {
      event.preventDefault();
    }
  }

  checkIfDateOfBirthIsGreaterThanCurrentDate(enteredDateOfBirth: any) {
    enteredDateOfBirth = new Date(enteredDateOfBirth);
    enteredDateOfBirth.setHours(0, 0, 0, 0);
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return enteredDateOfBirth >= currentDate;
  }
  closeModal() {
    this.dialogRef.close({ goBackToSearch: false });
  }
  monthName(value1: string) {
    if (value1) {
      if (this.months?.find(month => month.id === value1)) {
        return this.months?.find(month => month.id === value1)?.displayName;
      }
    }
    return null;
  }
  removeAllSpace() {
    setTimeout(() => {
      this.patientInformationObject.patientInfo.externalId = this.patientInformationObject.patientInfo.externalId.replaceAll(/\s/g, '');
    }, 0);
  }
  validatePatientInfoExtendedInfo() {
    this.isValid = true;
    let isLeapYear =
      parseInt(this.dateOfBirthObject.dobYear) % 100 === 0
        ? parseInt(this.dateOfBirthObject.dobYear) % 400 === 0
        : parseInt(this.dateOfBirthObject.dobYear) % 4 === 0;
    if (this.patientInformationObject.patientInfo && !this.patientInformationObject.patientInfo.externalId.trim()) {
      this.toastr.warning(`Please enter external Id`);
      this.isValid = false;
    }
    else if (this.patientInformationObject.patientInfo && this.patientInformationObject.patientInfo.externalId.length > 100) {
      this.toastr.warning(`External Id should be less than 100 characters`);
      this.isValid = false;
    }
    else if (this.patientInformationObject.patientInfo && !this.patientInformationObject.patientInfo.firstName.trim()) {
      this.toastr.warning(`Please enter First Name`);
      this.isValid = false;
    }
    else if (this.patientInformationObject.patientInfo && this.patientInformationObject.patientInfo.firstName.length > 60) {
      this.toastr.warning(`First name should be less than 60 characters`);
      this.isValid = false;
    }
    else if (this.patientInformationObject.patientInfo && this.patientInformationObject.patientInfo.lastName.length > 60) {
      this.toastr.warning(`Last name should be less than 60 characters`);
      this.isValid = false;
    }
    else if (this.patientInformationObject.patientInfo && this.patientInformationObject.patientInfo.firstName.trim() && !this.nameRegex.test(this.patientInformationObject.patientInfo.firstName)) {
      this.toastr.warning(`Please enter valid First Name`);
      this.isValid = false;
    }
    else if (this.patientInformationObject.patientInfo && !this.patientInformationObject.patientInfo.lastName.trim()) {
      this.toastr.warning(`Please enter Last Name`);
      this.isValid = false;
    }
    else if (this.patientInformationObject.patientInfo && this.patientInformationObject.patientInfo.lastName.trim() && !this.nameRegex.test(this.patientInformationObject.patientInfo.lastName)) {
      this.toastr.warning(`Please enter valid Last Name`);
      this.isValid = false;
    }
    else if (this.patientInformationObject.patientInfo && this.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber && (this.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber.length !== 15 || !this.phoneRegex.test(this.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber))) {
      this.toastr.warning(`Please enter valid Phone Number`);
      this.isValid = false;
    }
    else if (this.patientInformationObject.patientInfo && this.patientInformationObject.patientInfo.emailAddresses[0] && this.patientInformationObject.patientInfo.emailAddresses[0].email && !this.emailRegex.test(this.patientInformationObject.patientInfo.emailAddresses[0].email)) {
      // if (!this.emailRegex.test(this.patientInformationObject.patientInfo.emailAddresses[0].email)) {
      this.toastr.warning(`Enter a valid Confidential Email`);
      this.isValid = false;
      // }
    }
    else if (this.patientInformationObject.patientInfo && this.patientInformationObject.patientInfo.emailAddresses[0].email.length > 100) {
      this.toastr.warning(`Email address should be less than 100 characters`);
      this.isValid = false;
    }
    else if (this.patientInformationObject.patientInfo && !this.patientInformationObject.patientInfo.birthGender) {
      this.toastr.warning(`Please enter your  Gender`);
      this.isValid = false;
    }
    else if (this.dateOfBirthObject && !this.dateOfBirthObject.dobMonth) {
      this.toastr.warning(`Please enter your  DOB Month`);
      this.isValid = false;
    }
    else if (this.dateOfBirthObject && !this.dateOfBirthObject.dobDay) {
      this.toastr.warning(`Please enter your  DOB Day`);
      this.isValid = false;
    }
    else if (this.dateOfBirthObject && !this.dateOfBirthObject.dobYear) {
      this.toastr.warning(`Please enter your  DOB Year`);
      this.isValid = false;
    }
    else if (this.isValid && this.dateOfBirthObject.dobMonth && this.dateOfBirthObject.dobDay && this.dateOfBirthObject.dobYear) {
      if (this.dateOfBirthObject.dobMonth < 10 && this.dateOfBirthObject.dobDay >= 10) {
        this.patientInformationObject.patientInfo.dateOfBirth = `${this.dateOfBirthObject.dobMonth}/${this.dateOfBirthObject.dobDay}/${this.dateOfBirthObject.dobYear}`;
      } else if (this.dateOfBirthObject.dobDay < 10 && this.dateOfBirthObject.dobMonth >= 10) {
        this.patientInformationObject.patientInfo.dateOfBirth = `${this.dateOfBirthObject.dobMonth}/${this.dateOfBirthObject.dobDay}/${this.dateOfBirthObject.dobYear}`;
      } else if (this.dateOfBirthObject.dobDay < 10 && this.dateOfBirthObject.dobMonth < 10) {
        this.patientInformationObject.patientInfo.dateOfBirth = `${this.dateOfBirthObject.dobMonth}/${this.dateOfBirthObject.dobDay}/${this.dateOfBirthObject.dobYear}`;
      } else {
        this.patientInformationObject.patientInfo.dateOfBirth = `${this.dateOfBirthObject.dobMonth}/${this.dateOfBirthObject.dobDay}/${this.dateOfBirthObject.dobYear}`;
      }
      var date = moment(this.patientInformationObject.patientInfo.dateOfBirth);
      if (date.isValid()) {
        var currentDateTime = new Date();
        var expirationDateTime = new Date(this.patientInformationObject.patientInfo.dateOfBirth);
        if (expirationDateTime.getTime() > new Date().getTime()) {
          this.toastr.warning("Birth date can't be greater than or equal to the current date");
          this.isValid = false;
        } else if (+(this.dateOfBirthObject.dobYear) < 1900) {
          this.toastr.warning("Birth year should be greater than or equal to 1900.");
          this.isValid = false;
        } else if (expirationDateTime.getMonth() === currentDateTime.getMonth() && expirationDateTime.getFullYear() === currentDateTime.getFullYear() && expirationDateTime.getDate() === currentDateTime.getDate()) {
          this.toastr.warning("Birth date can't be greater than or equal to the current date");
          this.isValid = false;
        } else if (
          this.dateOfBirthObject.dobMonth.toString() == "02" &&
          this.dateOfBirthObject.dobDay > 28 &&
          !isLeapYear
        ) {
          this.toastr.warning('Invalid birth date');
          this.isValid = false;
        }
        else {
          this.isValid = true;
        }
      } else {
        this.toastr.warning("Date of birth is invalid");
        this.isValid = false;
      }
    }

    return this.isValid;
  }
  formatNumber() {
    this.formatPhoneNumberAndValidate();
  }
  formatPhoneNumberAndValidate() {
    var filterPhoneNumber = function (phoneNumber: string) {
      if (!phoneNumber) { return ''; }
      var value = phoneNumber.toString().trim().replace(/^\+/, '');
      if (value.match(/[^0-9]/)) {
        return phoneNumber;
      }
      var country, city, number = value;
      if (number) {
        if (number.length > 6) {
          number = number.slice(0, 3) + '-' + number.slice(3, 6) + '-' + number.slice(6, 10);
        }
        else if (number.length > 3) {
          number = number.slice(0, 3) + '-' + number.slice(3, 6);
        } return ('+1 ' + number).trim();
      }
      else { return '+1 ' + number; }
    };

    this.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber = this.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber.replace(/-/g, "");
    if (this.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber.includes('+'))
      this.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber = this.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber.slice(3);
    this.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber = filterPhoneNumber(this.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber);
  }
  createPatientInformation() {
    if (this.validatePatientInfoExtendedInfo()) {
      // this.toastr.warning("good.","");
      var checkObject: checkExternalIdExistsPayload = {
        externalId: "",
      };
      if (!this.__temporaryPassword) {
        this.__temporaryPassword = Math.floor(100000 + Math.random() * 900000);
      }
      checkObject.externalId = this.patientInformationObject.patientInfo.externalId;

      var root: any = this;

      if (checkObject.externalId && checkObject.externalId.length > 0) {
        this.createNewPatientService.checkExternalIdAlreadyExistsOrNot(checkObject)
          .then((result: any) => {
            if (result && result.data) {
              const checkResult: checkExternalIdExistsOrNot = {
                data: result.data,
                isActionSuccess: result.isActionSuccess,
              };
              if (checkResult && checkResult.data && checkResult.data.isExternalIdAlreadyPresent) {
                root.toastr.warning("External Id already exists, please enter another one.");
              }
              else if (checkResult && checkResult.data && !checkResult.data.isExternalIdAlreadyPresent) {
                var tempPhone = root.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber;

                root.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber = root.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber.replace(/-/g, "");
                root.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber = root.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber.slice(3);

                var email = root.patientInformationObject.patientInfo.emailAddresses[0].email;
                root.patientInformationObject.patientInfo.displayName = `${root.patientInformationObject.patientInfo.lastName}, ${root.patientInformationObject.patientInfo.firstName}`;
                root.patientInformationObject.patientInfo.dateOfBirth = `${root.dateOfBirthObject.dobMonth}/${root.dateOfBirthObject.dobDay}/${root.dateOfBirthObject.dobYear}`;

                root.createNewPatientService.createNewPatient(root.patientInformationObject.patientInfo)
                  .then((resultData: any) => {
                    if (resultData) {
                      const result: createNewPatient = {
                        data: resultData.data,
                        isAlreadyPresent: resultData.isActionSuccess,
                      }
                      root.toastr.success("Member created successfully.");
                      if (root.showBackToSearchBtn)
                        root.dialogRef.close({ associateWithNewPatient: result });
                      else
                        root.dialogRef.close(true);
                    }
                  }).catch(function (ex: any) {
                    root.patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber = tempPhone;
                    console.log(ex);
                    root.toastr.error("Unknown error occurred. Please contact administrator.");
                  })
              }
            }
          }).catch(function (ex: any) {
            console.log(ex);
            root.toastr.error("Unknown error occurred. Please contact administrator.");
          })
      }
    }
  }

  // Only AlphaNumeric
  keyPressAlphaNumeric(event: any) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9 ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  backToSearch() {
    this.dialogRef.close({ goBackToSearch: true })
  }
  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData('text/plain');

    if (pastedText) {
      const isNumeric = /^\d+$/.test(pastedText); // Check if pasted text is numeric

      if (!isNumeric) {
        event.preventDefault(); // Prevent the paste action
      }
    }
  }
  showBackToSearchBtn: boolean = true;
  ngOnInit(): void {
    if (this.data && this.data.showBackToSearch) {
      this.showBackToSearchBtn = true;
    }
  }
}
