import { Routes } from '@angular/router';
import { RealTimeDashboardComponent } from './apps/focus/dashboard/real-time-dashboard/real-time-dashboard.component';
import { routeGuardService } from './apps/route-guard/routing-guard.sevice';
import { UnsavedChangesGuard } from './apps/unsaved-changes.guard';


export const routes: Routes = [
  {
    path: '', loadComponent: () =>
      import('./apps/app-launcher/app-launcher.component').then((x) => x.AppLauncherComponent), pathMatch: "full"
  },
  {
    path: 'simulate', loadComponent: () =>
      import('./apps/patient-simulator/patient-simulator.component').then((x) => x.PatientSimulatorComponent), pathMatch: "full"
    , children: [
      {
        path: '', loadComponent: () =>
          import('./apps/patient-simulator/simulator-list/simulator-list.component').then((x) => x.SimulatorListComponent), pathMatch: 'full'
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/patient-simulator/simulator-list/simulator-list.component').then((x) => x.SimulatorListComponent), pathMatch: 'full'
      },
    ]
  },
  { path: 'apps', loadComponent: () => import('./apps/app-launcher/app-launcher.component').then((x) => x.AppLauncherComponent) },
  {
    path: 'focus',
    loadComponent: () =>
      import('./apps/focus/focus.component').then((x) => x.FocusComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./apps/focus/home/home.component').then((x) => x.HomeComponent),
          canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: 'patients', loadComponent: () =>
          import('./apps/focus/patients/patients.component').then((x) => x.PatientsComponent),
          canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: 'dashboard', loadComponent: () =>
          import('./apps/focus/dashboard/dashboard.component').then((x) => x.DashboardComponent),
        children: [
          {
            path: '', pathMatch: 'full', redirectTo: "real-time",canDeactivate: [UnsavedChangesGuard]
          },
          {
            path: 'real-time', loadComponent: () =>
              import('./apps/focus/dashboard/real-time-dashboard/real-time-dashboard.component').then((x) => RealTimeDashboardComponent),
              canDeactivate: [UnsavedChangesGuard]
          },
          {
            path: 'digital-engagement', loadComponent: () =>
              import('./apps/focus/dashboard/digital-engagement-dashboard/digital-engagement-dashboard.component').then((x) => x.DigitalEngagementDashboardComponent),
              canDeactivate: [UnsavedChangesGuard]
          },
          {
            path: 'live-voice', loadComponent: () =>
              import('./apps/focus/dashboard/live-voice-dashboard/live-voice-dashboard.component').then((x) => x.LiveVoiceDashboardComponent),
              canDeactivate: [UnsavedChangesGuard]
          },
        ],
      },
      {
        path: 'inbox', loadComponent: () =>
          import('./apps/focus/inbox/inbox.component').then((x) => x.InboxComponent),
          canDeactivate: [UnsavedChangesGuard]
      },
       {
        path: 'inbox/patient-profile/:id/:workQueueId', loadComponent: () =>
          import('./apps/focus/patients/patient-profile/patient-profile.component').then((x) => x.PatientProfileComponent),
          canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: 'campaign', loadComponent: () =>
          import('./apps/focus/campaign/campaign.component').then((x) => x.CampaignComponent),
          canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: 'campaign/create-new-campaign', loadComponent: () =>
          import('./apps/focus/campaign/create-new-campaign/create-new-campaign.component').then((x) => x.CreateNewCampaignComponent),
          canDeactivate: [UnsavedChangesGuard]
      },
    
      {
        path: 'patients/patient-profile/:id', loadComponent: () =>
          import('./apps/focus/patients/patient-profile/patient-profile.component').then((x) => x.PatientProfileComponent),
          canDeactivate: [UnsavedChangesGuard]
      },
       {
        path: 'reports', loadComponent: () =>
          import('./apps/focus/reports/reports.component').then((x) => x.ReportsComponent),
          canDeactivate: [UnsavedChangesGuard]
      },
      // {
      //   path: 'realTimeDashboard', loadComponent: () =>
      //     import('./apps/focus/real-time-dashboard/real-time-dashboard.component').then((x) => x.RealTimeDashboardComponent)
      // },
    ],
    canActivate: [routeGuardService],
  },
  {
    path: 'twilio-phone-number',
    loadComponent: () =>
      import('./apps/twilio-phone-number/twilio-phone-number.component').then((x) => x.TwilioPhoneNumberComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./apps/twilio-phone-number/list/list.component').then((x) => x.ListComponent),
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/twilio-phone-number/list/list.component').then((x) => x.ListComponent),
      },
      {
        path: 'edit/:id', loadComponent: () =>
          import('./apps/twilio-phone-number/edit/edit.component').then((x) => x.EditComponent)
      },
      {
        path: 'clientPhoneNumber', loadComponent: () =>
          import('./apps/twilio-phone-number/twilio-client-phone-number/twilio-client-phone-number.component').then((x) => x.TwilioClientPhoneNumberComponent)
      }
      /* ,
      {
        path: 'patients/patient-profile', loadComponent: () =>
          import('./apps/twilio-phone-number/list/list.component').then((x) => x.PatientProfileComponent)
      }, */
    ],
    canActivate: [routeGuardService]
  },
  {
    path: 'admin-console', loadComponent: () =>
      import('./apps/admin-console/admin-console.component').then((x) => x.AdminConsoleComponent),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/admin-console/admin-console-library/admin-console-library.component').then((x) => x.AdminConsoleLibraryComponent)
      },
      {
        path: 'tools', loadComponent: () =>
          import('./apps/admin-console/admin-console-tools/admin-console-tools.component').then((x) => x.AdminConsoleToolsComponent)
        },
        {
          path:'sso-setting',loadComponent:() =>
          import('./apps/admin-console/application-setting/sso-setting/sso-setting.component').then((x)=>x.SSOSettingComponent)
        }
    ],
    canActivate: [routeGuardService]
  },

  {
    path: 'system-service-email', loadComponent: () =>
      import('./apps/system-services-email-v2/system-services-email-v2.component').then((x) => x.SystemServicesEmailV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/system-services-email-v2/list/list.component').then((x) => x.ListComponent), pathMatch: 'full'
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/system-services-email-v2/list/list.component').then((x) => x.ListComponent),
      },
      {
        path: 'listView/:id', loadComponent: () =>
          import('./apps/system-services-email-v2/edit/list-view.component').then((x) => x.ListViewComponent)
      }
    ]
  },

  {
    path: 'business-log', loadComponent: () =>
      import('./apps/business-log/business-log.component').then((x) => x.BusinessLogComponent),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/business-log/list/list.component').then((x) => x.ListComponent), pathMatch: 'full'
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/business-log/list/list.component').then((x) => x.ListComponent),
      },
      {
        path: 'listView/:id', loadComponent: () =>
          import('./apps/business-log/list-view/business-log-list-view.component').then((x) => x.BusinessLogListViewComponent)
      }
    ]
  },

  // {
  //   path: 'system-service-email', loadComponent: () =>
  //     import('./apps/system-services-email-v2/system-services-email-v2.component').then((x) => x.SystemServicesEmailV2Component),
  //   children: [
  //     {
  //       path: '', loadComponent: () =>
  //         import('./apps/system-services-email-v2/list/list.component').then((x) => x.ListComponent), pathMatch: 'full'
  //     },
  //     {
  //       path: 'list', loadComponent: () =>
  //         import('./apps/system-services-email-v2/list/list.component').then((x) => x.ListComponent),
  //     },
  //     {
  //       path: 'listView/:id', loadComponent: () =>
  //         import('./apps/system-services-email-v2/list-view/list-view.component').then((x) => x.ListViewComponent)
  //     }
  // ]
  // },
  {
    path: 'task-status/list', loadComponent: () =>
      import('./apps/task-status-v2/task-status-v2.component').then((x) => x.TaskStatusV2Component)
  },
  {
    path: 'facebook-settings', loadComponent: () =>
      import('./apps/facebook-setting-v2/facebook-setting-v2.component').then((x) => x.FacebookSettingV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/facebook-setting-v2/facebook-setting-list-v2/facebook-setting-list-v2.component').then((x) => x.FacebookSettingListV2Component), pathMatch: 'full'
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/facebook-setting-v2/facebook-setting-list-v2/facebook-setting-list-v2.component').then((x) => x.FacebookSettingListV2Component),
      },
      {
        path: 'edit', loadComponent: () =>
          import('./apps/facebook-setting-v2/edit/edit.component').then((x) => x.EditComponent)
      },
      {
        path: 'callback', loadComponent: () =>
          import('./apps/facebook-setting-v2/callback/callback.component').then((x) => x.CallbackComponent)
      },

    ]
  },
  {
    path: 'business-unit', loadComponent: () =>
      import('./apps/business-unit-v2/business-unit-v2.component').then((x) => x.BusinessUnitV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/business-unit-v2/business-unit-list/business-unit-list.component').then((x) => x.BusinessUnitListComponent), pathMatch: 'full'
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/business-unit-v2/business-unit-list/business-unit-list.component').then((x) => x.BusinessUnitListComponent),
      },
      {
        path: 'listView/:id', loadComponent: () =>
          import('./apps/business-unit-v2/edit-business-unit/edit-business-unit.component').then((x) => x.EditBusinessUnitComponent)
      }
    ]
  },
  {
    path: 'action-metrices', loadComponent: () =>
      import('./apps/action-metrices/action-metrices.component').then((x) => x.ActionMetricesComponent),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/action-metrices/action-metrices-list/action-metrices-list.component').then((x) => x.ActionMetricesListComponent), pathMatch: 'full'
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/action-metrices/action-metrices-list/action-metrices-list.component').then((x) => x.ActionMetricesListComponent)
      },
      {
        path: 'edit/:id', loadComponent: () =>
          import('./apps/action-metrices/action-metrices-edit/action-metrices-edit.component').then((x) => x.ActionMetricesEditComponent)
      }

    ],
    canActivate: [routeGuardService]
  },
  {
    path: 'app-settings', loadComponent: () =>
      import('./apps/app-settings-v2/app-settings-v2.component').then((x) => x.AppSettingsV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/app-settings-v2/app-settings-list-view/app-settings-list-view.component').then((x) => x.AppSettingsListViewComponent)
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/app-settings-v2/app-settings-list-view/app-settings-list-view.component').then((x) => x.AppSettingsListViewComponent)
      },
      {
        path: 'list/:id', loadComponent: () =>
          import('./apps/app-settings-v2/app-settings-edit-view/app-settings-edit-view.component').then((x) => x.AppSettingsEditViewComponent)
      }
    ]
  },
  {
    title: "Upfront - Permissions",
    path: 'permissions', loadComponent: () =>
      import('./apps/permissions-v2/permissions-v2.component').then((x) => x.PermissionsV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/permissions-v2/permissions-list/permissions-list.component').then((x) => x.PermissionsListComponent), pathMatch: 'full'
      },
      {
        path: 'list',
        loadComponent: () => import('./apps/permissions-v2/permissions-list/permissions-list.component').then((x) => x.PermissionsListComponent)
      },
      {
        path: 'listView/:id', loadComponent: () =>
          import('./apps/permissions-v2/edit-permissions/edit-permissions.component').then((x) => x.EditPermissionsComponent)
      }
    ]
  },
  {
    path: 'email-template', loadComponent: () =>
      import('./apps/email-template-v2/email-template-v2.component').then((x) => x.EmailTemplateV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/email-template-v2/list/list.component').then((x) => x.ListComponent), pathMatch: 'full'
      },
      {
        path: 'edit', loadComponent: () =>
          import('./apps/email-template-v2/edit/edit.component').then((x) => x.EditComponent), pathMatch: 'full'
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/email-template-v2/list/list.component').then((x) => x.ListComponent),
      },
    ]
  },
  {
    path: 'work-queue', loadComponent: () =>
      import('./apps/work-queues-v2/work-queues-v2.component').then((x) => x.WorkQueuesV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/work-queues-v2/work-queues-list/work-queues-list.component').then((x) => x.WorkQueuesListComponent), pathMatch: 'full'
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/work-queues-v2/work-queues-list/work-queues-list.component').then((x) => x.WorkQueuesListComponent),
      },
      {
        path: 'listView/:id', loadComponent: () =>
          import('./apps/work-queues-v2/work-queues-edit/work-queues-edit.component').then((x) => x.WorkQueuesEditComponent)
      }
    ]
  },
  {
    path: 'task', loadComponent: () =>
      import('./apps/task-v2/task-v2.component').then((x) => x.TaskV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/task-v2/task-list/task-list.component').then((x) => x.TaskListComponent), pathMatch: 'full'
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/task-v2/task-list/task-list.component').then((x) => x.TaskListComponent),
      },
      {
        path: 'taskView/:id', loadComponent: () =>
          import('./apps/task-v2/task-view/task-view.component').then((x) => x.TaskViewComponent)
      },
    ]
  },
  {
    path: 'logs', loadComponent: () =>
      import('./apps/logs-v2/logs-v2.component').then((x) => x.LogsV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/logs-v2/logs-list/logs-list.component').then((x) => x.LogsListComponent), pathMatch: 'full'
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/logs-v2/logs-list/logs-list.component').then((x) => x.LogsListComponent),
      },
      {
        path: 'listView/:id', loadComponent: () =>
          import('./apps/logs-v2/logs-list-view/logs-list-view.component').then((x) => x.LogsListViewComponent)
      }

    ]
    ,
    canActivate: [routeGuardService]
  },
  {
    title: "Upfront - Surveys",
    path: 'surveys', loadComponent: ()=> 
      import('./apps/surveys-v2/surveys-v2.component').then((x)=> x.SurveysV2Component),
      children: [
        {
          path: '', loadComponent: ()=>
          import('./apps/surveys-v2/surveys-list-view/surveys-list-view.component').then((x)=>x.SurveysListViewComponent), pathMatch: 'full'
        },
        {
          path: 'list', loadComponent: ()=>
          import('./apps/surveys-v2/surveys-list-view/surveys-list-view.component').then((x)=>x.SurveysListViewComponent)
        },
        {
          path: 'list/:id', loadComponent: ()=>
          import('./apps/surveys-v2/surveys-edit-view/surveys-edit-view.component').then((x)=>x.SurveysEditViewComponent)
        }
      ],
      canActivate: [routeGuardService]
  },
  {
    path: 'user', loadComponent: () =>
      import('./apps/user-v2/user-v2.component').then((x) => x.UserV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/user-v2/user-list/user-list.component').then((x) => x.UserListComponent), pathMatch: 'full'
      },
      {
        path: 'list', loadComponent: () =>
          import('./apps/user-v2/user-list/user-list.component').then((x) => x.UserListComponent),
      },
      {
        path: 'listView/:id', loadComponent: () =>
          import('./apps/user-v2/user-edit/user-edit.component').then((x) => x.UserEditComponent)
      }
    ]
  },
  {
    title: "Upfront - Image Resource",
    path: 'image-resource', loadComponent: () =>
      import('./apps/image-resource-v2/image-resource-v2.component').then((x) => x.ImageResourceV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/image-resource-v2/image-resource-list/image-resource-list.component').then((x) => x.ImageResourceListComponent), pathMatch: 'full'
      },
      {
        path: 'list',
        loadComponent: () => import('./apps/image-resource-v2/image-resource-list/image-resource-list.component').then((x) => x.ImageResourceListComponent)
      },
      {
        path: 'listView/:id', loadComponent: () =>
          import('./apps/image-resource-v2/edit-image-resource/edit-image-resource.component').then((x) => x.EditImageResourceComponent)
      }
    ],
    canActivate: [routeGuardService]
  },

  {
    path: 'code-category', loadComponent: () =>
      import('./apps/code-category-v2/code-category-v2.component').then((x) => x.CodeCategoryV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/code-category-v2/code-category-list/code-category-list.component').then((x) =>x.CodeCategoryListComponent ), pathMatch: 'full'
      },
      {
        path: 'list', loadComponent: () =>
        import('./apps/code-category-v2/code-category-list/code-category-list.component').then((x) =>x.CodeCategoryListComponent ), pathMatch: 'full'
      },
      {
        path: 'edit/:id', loadComponent: () =>
          import('./apps/code-category-v2/code-category-edit/code-category-edit.component').then((x) => x.CodeCategoryEditComponent )
      },
      
    ]
  },
  {
    path: 'microsite-builder', loadComponent: () =>
      import('./apps/journey-flow-v2/journey-flow-v2.component').then((x) => x.JourneyFlowV2Component),
    children: [
      {
        path: '', loadComponent: () =>
          import('./apps/journey-flow-v2/list/list.component').then((x) => x.ListComponent), pathMatch: 'full'
      },
      {
        path: 'edit/:id', loadComponent: () =>
          import('./apps/journey-flow-v2/edit/edit.component').then((x) => x.EditComponent)
      }
    ]
  },
  {
    path: 'epic-settings', loadComponent: () =>
      import('./apps/epic-setting/epic-setting.component').then(x => x.EpicSettingComponent),
      children: [
        {
          path:'', loadComponent: () =>
          import ('./apps/epic-setting/list/list.component').then(x => x.ListComponent),pathMatch: 'full'
        },
        {
          path:'edit/:id', loadComponent: () =>
          import ('./apps/epic-setting/edit/edit.component').then(x => x.EditComponent),pathMatch: 'full'
        }
      ]
  },
  {
    path: '**', loadComponent: () =>
      import('./page-not-found/page-not-found.component').then((x) => x.PageNotFoundComponent)
  },

];

export const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./app-routing').then((x) => x.routes),
  }
];
