<div class="modal-header head" style="padding: 15px 30px; display: block;">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 text-center" style="margin-top: 5px;">
      <span *ngIf="!isConferenceCallStarted" style="font-size: 16px;color:#21464F">Call with
        {{ associatedPatientName || patientDetails.patientName | titlecase}}</span>
      <span *ngIf="isConferenceCallStarted" style="font-size: 16px;color:#21464F">Call with
        {{ associatedPatientName || patientDetails.patientName | titlecase}} and others</span>
      <button style="
          border: none;
          outline: none;
          float: right;
          background: transparent;
        " (click)="closeModal()">
        <img src="../../assets/images/close.svg" style="
            color: white !important;
            border-radius: 4px;
            width: 25px;
          " />
      </button>
    </div>
  </div>
</div>
<div class="modal-body">
  <div class="row bodyWrapper">
    <div class="col-md-6 col-sm-6 col-xs-6 h-100"
      style="border-right: 1px solid #E6EBED;display: flex;justify-content: center;overflow-x: none; overflow-y: auto;">

      <div *ngIf="!conferenceCallParticipant" class=" patient-profile" style="display: flex;align-items: center;flex-direction: column;
      justify-content: center;">
        <div class="d-flex flex-column align-items-center">
          <span class="on-hold d-flex justify-content-center align-items-center flex-column" *ngIf="holdStatus"
            (click)="callHoldStatus()">
            <i class="fa fa-play" aria-hidden="true" style="font-size:13px;margin-top:5px;"></i>
            <span
              style="font-size: 8px; background:var(--focus-theme-green); border-radius: 5px; padding:0 5px;margin-top:5px;font-weight: 600;">
              On Hold
            </span>
          </span>
          <img
            *ngIf="patientDetails.patientProfilePicture && patientDetails.patientProfilePicture !== defaultUserImgUrl && patientDetails.patientProfilePicture !== 'img/pbadmin.svg' &&  patientDetails.patientProfilePicture !== 'img/default-user.svg' "
            [src]="patientDetails.patientProfilePicture"
            style="width: 70px;height:70px;border-radius: 50%;padding: 2px; cursor: pointer;" alt=""
            (click)="callHoldStatus()" loading="lazy" />
          <span appRandomBackgroundColorGenerator (click)="callHoldStatus()"
            class="left-initials d-flex justify-content-center align-items-center cursor-pointer"
            *ngIf="patientDetails.patientProfilePicture === defaultUserImgUrl ||  patientDetails.patientProfilePicture === 'img/pbadmin.svg'  || patientDetails.patientName =='UNKNOWN_PATIENT'">{{patientDetails.patientInitials}}</span>
          <div style="margin-top: 10px; align-self: center;text-align: center;">
            <h4 style="margin-bottom: 0;">
              {{associatedPatientName || patientDetails.patientName | titlecase}}</h4>
            <div style="display: flex;justify-content:center;"><span
                style="font-size: 12px;color:#75787B">{{patientPhoneNumber}}</span></div>

          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="conferenceCallParticipant" style="display:flex;align-content: center; justify-content: center;
      flex-wrap: wrap;padding: 15px;">
        <div class="patient-profile d-flex col-6 flex-column align-items-center">
          <div class="d-flex flex-column align-items-center">
            <span class="on-hold d-flex justify-content-center align-items-center flex-column" *ngIf="holdStatus"
              (click)="callHoldStatus()">
              <i class="fa fa-play" aria-hidden="true" style="font-size:13px;margin-top:5px;"></i>
              <span
                style="font-size: 8px; background:var(--focus-theme-green); border-radius: 5px; padding:0 5px;margin-top:5px;font-weight: 600;">
                On Hold
              </span>
            </span>
            <img
              *ngIf="patientDetails.patientProfilePicture && patientDetails.patientProfilePicture !== defaultUserImgUrl && patientDetails.patientProfilePicture !== 'img/pbadmin.svg' &&  patientDetails.patientProfilePicture !== 'img/default-user.svg' "
              [src]="patientDetails.patientProfilePicture"
              style="width: 70px;height:70px;border-radius: 50%;padding: 2px;  cursor: pointer;" alt=""
              (click)="callHoldStatus()" loading="lazy" />

            <span style="background-color: #4b7e52;" (click)="callHoldStatus()"
              class="left-initials d-flex justify-content-center align-items-center cursor-pointer"
              *ngIf="!patientDetails.patientProfilePicture || patientDetails.patientProfilePicture === 'img/default-user.png' || patientDetails.patientProfilePicture === 'img/default-user.svg' || patientDetails.patientName =='UNKNOWN_PATIENT'">{{patientDetails.patientInitials}}</span>
            <div style=" text-align: center;">
              <h5 style="margin-bottom: 0;">
                {{associatedPatientName || patientDetails.patientName | titlecase}}</h5>
              <!-- <span style="font-size: 12px;color:#75787B">Patient</span> -->
            </div>
          </div>
        </div>
        <div *ngFor="let participant of providerQueueList; trackBy: activityTrackBy"
          class="left-agent-profile d-flex col-6 align-items-center flex-column">
          <!-- <div class="col-6" *ngIf=""></div> -->
          <img
            *ngIf="participant.profilePictureUrl && participant.profilePictureUrl !== 'img/default-user.png' && participant.profilePictureUrl !== 'img/default-user.svg'"
            [src]="participant.profilePictureUrl" alt="" class="profileImage"
            style="width: 70px;height:70px;border-radius: 50%;" loading="lazy" />
          <span appRandomBackgroundColorGenerator class="left-initials d-flex justify-content-center align-items-center"
            *ngIf="!participant.profilePictureUrl || participant.profilePictureUrl === 'img/default-user.png' || participant.profilePictureUrl === 'img/default-user.svg' ">{{participant.agentInitials}}</span>
          <div style="text-align: center;">
            <h5 style="margin-bottom: 0; text-transform:capitalize;">
              <!-- <span *ngIf="participant.participantType === 'external'">+1 {{participant.displayName.slice(2, 5)}}-{{participant.displayName.slice(5, 8)}}-{{participant.displayName.slice(8)}}</span>-->
              <span *ngIf="participant.participantType !== 'external'">{{participant.displayName}}</span>
              <span *ngIf="participant.participantType === 'external'">External Agent</span>
              <span class="call-mute" *ngIf="!participant.isMuted && participant.participantType !== 'external'"
                style="margin-left:10px">
                <i class="fa fa-microphone" aria-hidden="true"></i>
              </span>
              <span class="call-unmute" *ngIf="participant.isMuted && participant.participantType !== 'external'"
                style="margin-left:10px">
                <i class="fa fa-microphone-slash" aria-hidden="true"></i>
              </span>
            </h5>
          </div>
        </div>
        <div *ngFor="let participant of externalQueueList"
          class="left-agent-profile d-flex col-6 align-items-center flex-column">
          <span appRandomBackgroundColorGenerator
            class="left-initials d-flex justify-content-center align-items-center">EA</span>
          <div style="text-align: center;">
            <h5 style="margin-bottom: 0;">
              <span>External Agent</span>
            </h5>
            <h5 style="font-size:12px; color: #75787B" class="mb-0 animate-dial">(Dialing...)</h5>
          </div>
        </div>
      </div>

    </div>
    <div class="col-md-6 col-sm-6 col-xs-6 h-100" style="overflow: hidden;" cdkScrollable>
      <div class="row">
        <div class="col-sm-12" style="padding: 15px !important;">
          <div style="color:#333333;font-size:17px;padding:5px 0px">Add People</div>
        </div>
      </div>
      <div class="row" style="padding-bottom:15px">
        <mat-radio-group aria-label="Select an option" class="w-100 d-flex" [(ngModel)]="usertype">
          <div class="col-6">
            <mat-radio-button value="internal" (change)="changeUsertype($event)">Internal User</mat-radio-button>
          </div>
          <div class="col-6">
            <mat-radio-button value="external" (change)="changeUsertype($event)">External User</mat-radio-button>
          </div>
        </mat-radio-group>
      </div>

      <div class="search-holder">
        <div *ngIf="usertype === 'external'" style="display: flex;">
          <input tabindex="1" class="form-control external-user-input" type="text" placeholder="Type a number"
            (keyup)="formatNumber()" (keypress)="checkIfNumber($event)" [(ngModel)]="externalAgentNumber" maxlength="15"
            (paste)="onPaste($event)" (keydown.enter)="addToConference(externalAgentNumber)">
          <i class="fa fa-plus cursor-pointer" aria-hidden="true"
            style="color:white;background-color: #00b1e8 !important;" (click)="addToConference(externalAgentNumber)"
            [ngClass]="{ 'disabled': externalAgentNumber.length !== 15 }"></i>
        </div>
        <div *ngIf="usertype === 'internal'">
          <i class="fa fa-search cursor-pointer" aria-hidden="true"></i>
          <mat-form-field appearance="fill" class="w-100">
            <input type="text" id="search" name="search" placeholder="Type a name" matInput
              [(ngModel)]="twilioWorkerName" [matAutocomplete]="auto1" maxlength="30"
              (keydown.enter)="selectOptionByEnter()">
            <mat-autocomplete #auto1="matAutocomplete" class="availableWorkersDropdown">
              <mat-option class="propertyOptions"
                *ngFor="let type of searchFilterObject(allAvailableWorkers, twilioWorkerName)"
                [value]="type.displayName"
                style="max-height:150px !important; height: auto; overflow-y: auto;overflow-x: hidden;border-bottom: 1px solid #c9d5de;padding: 0 8px !important;"
                (click)="selectOption(type);">
                <div class="row" style="align-items:center;padding: 15px 0px;flex-wrap: unset;">
                  <div class="col-sm-1">
                    <img [src]="type.profilePictureUrl" loading="lazy"
                      style="width:38px;height:38px;border-radius: 50%;padding: 2px;margin-right:10px;" alt=""
                      *ngIf="type.profilePictureUrl && type.profilePictureUrl !== defaultUserImgUrl && type.profilePictureUrl !== 'img/pbadmin.svg' &&  type.profilePictureUrl !== 'img/default-user.svg'" />
                    <span style="width: 38px;height:38px;line-height: 38px;"
                      *ngIf="type.profilePictureUrl === defaultUserImgUrl ||  type.profilePictureUrl === 'img/pbadmin.svg' || !type.profilePictureUrl || type.profilePictureUrl==='img/default-user.svg'"
                      class="search-initials">{{type.initials}}</span>
                  </div>
                  <div class="col-sm-11" style="padding-left:33px;display:flex;flex-direction: column;">
                    <span
                      style="text-transform:capitalize;color: #333333;margin-bottom: 2px !important;line-height: 20px;font-size: 14px;"><b>{{type.displayName}}</b></span>
                    <span style="color:green;font-size:10px;line-height: 20px;"
                      *ngIf="type.activity==='Available'">{{type.activity}}</span>
                    <span style="color:rgb(20, 215, 206);font-size:10px;line-height: 20px;"
                      *ngIf="type.activity==='On Call'">{{type.activity}}</span>
                    <span style="color:#f8432b;font-size:10px;line-height: 20px;"
                      *ngIf="type.activity!=='Available' &&  type.activity !=='On Call'">{{type.activity}}</span>
                  </div>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <!-- <i class="fa fa-search cursor-pointer" aria-hidden="true"></i>
        <input type="text" class="form-control" id="search" name="search" [(ngModel)]="twilioWorkerName" maxlength="50"
          placeholder="Type a name or number" (ngModelChange)="showWorker()" /> -->
        <!-- <div style="max-height:150px !important;overflow-y: auto;overflow-x: hidden;" *ngIf="showDropDown">
          <div *ngFor="let workers of allAvailableWorkers" style="border:0.5px solid #c5d1d5;padding: 5px 10px;"
            (click)="selectOption(workers)">
            <div class="row" style="align-items:center;">
              <img
                *ngIf="workers.profilePictureUrl  && workers.profilePictureUrl !== 'img/default-user.png' && workers.profilePictureUrl !== 'img/default-user.svg'"
                [src]="workers.profilePictureUrl"
                style="width: 35px;height:35px;border-radius: 50%;padding: 2px;margin-right:10px;" alt="" />

              <span style="margin-right:10px;margin-left: 10px;"
                class="initials d-flex justify-content-center align-items-center"
                *ngIf="!workers.profilePictureUrl || workers.profilePictureUrl === 'img/default-user.png' || workers.profilePictureUrl === 'img/default-user.svg' ">{{workers.initials}}</span>
              <div class="d-flex flex-column "><span class="text-ellipsis"
                  style="color: #333333;margin-bottom: 2px !important;line-height: 20px;">
                  <b>{{workers.displayName}}</b></span>
                <span style="color:#00d176;font-size:12px;line-height: 20px;"
                  *ngIf="workers.activity==='Available'">{{workers.activity}}</span>
                <span style="color:#f8432b;font-size:12px;line-height: 20px;"
                  *ngIf="workers.activity!=='Available'">{{workers.activity}}</span>
              </div>
            </div>
          </div>
        </div> -->
        <div style="margin:40px -15px 0 -15px; padding:0 15px; overflow:auto; max-height:188px;">
          <div style="display: flex;align-items:center">
            <div>Current in this call </div>
            <div style="border-bottom:2px dashed #D9D9D6;width:215px;margin-left: 15px;"></div>
          </div>
          <div style="margin-top:10px;margin-bottom: 30px;">
            <div class="patient-profile" style="display: flex; align-items: center;">
              <img
                *ngIf=" patientDetails.patientProfilePicture && patientDetails.patientProfilePicture !== 'img/default-user.png' && patientDetails.patientProfilePicture !== 'img/default-user.svg' && patientDetails.patientName !='UNKNOWN_PATIENT'"
                [src]="patientDetails.patientProfilePicture"
                style="width: 38px;height: 38px;border-radius: 50%;padding: 2px;" alt="" loading="lazy" />
              <span style="background-color: #4b7e52;" class="initials d-flex justify-content-center align-items-center"
                *ngIf="patientDetails.patientProfilePicture === defaultUserImgUrl ||  patientDetails.patientProfilePicture === 'img/pbadmin.svg' || !patientDetails.patientProfilePicture || patientDetails.patientProfilePicture==='img/default-user.svg'">{{patientDetails.patientInitials}}</span>

              <div style="margin-left:10px;align-self: center; padding-top: 4px;">
                <h4 style="margin-bottom: 0;line-height: 1;">
                  {{associatedPatientName || patientDetails.patientName | titlecase}}</h4>
                <span style="font-size: 12px;color:#75787B">Patient</span>
              </div>
            </div>
            <div class="agent-profile" *ngFor="let dialed of dialAgent; trackBy: activityTrackBy"
              style="display: flex; align-items: center;margin-top: 20px;">
              <img
                *ngIf="dialed.profilePictureUrl && dialed.profilePictureUrl !== defaultUserImgUrl && dialed.profilePictureUrl !== 'img/pbadmin.svg' &&  dialed.profilePictureUrl !== 'img/default-user.svg'"
                [src]="dialed.profilePictureUrl" style="width: 38px;height: 38px;border-radius: 50%;padding: 2px;"
                alt="" loading="lazy" />
              <span appRandomBackgroundColorGenerator class="initials d-flex justify-content-center align-items-center"
                *ngIf="dialed.profilePictureUrl === defaultUserImgUrl ||  dialed.profilePictureUrl === 'img/pbadmin.svg' || !dialed.profilePictureUrl || dialed.profilePictureUrl==='img/default-user.svg'">{{dialed.initials}}</span>

              <div style="margin-left:10px;align-self: center; padding-top: 4px;">
                <h4 style="margin-bottom: 0;line-height: 1;">
                  {{dialed.displayName | titlecase}}</h4>
                <span style="font-size: 12px;color:#75787B" class="animate-dial">Dialing...</span>
              </div>
            </div>
            <div *ngFor="let participant of agentDetails; trackBy: activityTrackBy" class="agent-profile"
              style="display: flex;margin-top: 20px;">
              <img
                *ngIf="participant.profilePictureUrl && participant.profilePictureUrl !== 'img/default-user.png' && participant.profilePictureUrl !== 'img/default-user.svg'"
                [src]="participant.profilePictureUrl" alt="" class="profileImage"
                style="width: 35px;height:35px;border-radius: 50%;" loading="lazy" />
              <span appRandomBackgroundColorGenerator class="initials d-flex justify-content-center align-items-center"
                *ngIf="!participant.profilePictureUrl || participant.profilePictureUrl === 'img/default-user.png' || participant.profilePictureUrl === 'img/default-user.svg' ">{{participant.agentInitials}}</span>
              <div style="margin-left: 10px; align-self: center;">
                <h4 style="margin-bottom: 0; line-height: 1;text-transform: capitalize;">
                  <span *ngIf="participant.participantType === 'external'">+1 {{participant.displayName.slice(2,
                    5)}}-{{participant.displayName.slice(5, 8)}}-{{participant.displayName.slice(8)}}</span>
                  <span *ngIf="participant.participantType !== 'external'">{{participant.displayName}}</span>
                </h4>
                <span style="font-size: 12px; color:#75787B;"
                  *ngIf="participant.participantType === 'external'">External Agent</span>
                <span style="font-size: 12px; color:#75787B;"
                  *ngIf="participant.participantType !== 'external'">Agent</span>
              </div>
              <span class="call-mute" *ngIf="!participant.isMuted && participant.participantType !== 'external'"
                style="margin-left:auto">
                <i class="fa fa-microphone" aria-hidden="true"></i>
              </span>
              <span class="call-unmute" *ngIf="participant.isMuted && participant.participantType !== 'external'"
                style="margin-left:auto">
                <i class="fa fa-microphone-slash" aria-hidden="true"></i>
              </span>
            </div>


          </div>
        </div>
      </div>
    </div>

    <div class="main-dialer-wrapper" *ngIf="showDialer">
      <span class="open-dialer-icon" [ngClass]="{'disabled':isDialerDisabled}" (click)="openDialer();">
        <img src="../../../../../../assets/images/calls/Group 240781.svg" height="20" width="20" alt="Dialer icon"
          *ngIf="!openMainPanel && isDialerDisabled" loading="lazy" />
        <img src="../../../../../../assets/images/calls/Group 240781-1.svg" height="20" width="20" alt="Dialer icon"
          *ngIf="!openMainPanel && !isDialerDisabled" loading="lazy" />
        <img src="../../../../../../assets/images/calls/Close Dialer.svg" height="10" width="10" alt="Dialer icon"
          *ngIf="openMainPanel" loading="lazy" />
      </span>
      <mat-accordion>
        <mat-expansion-panel hideToggle class="main" style="border-radius: unset !important" [expanded]="openMainPanel">
          <div class="dialer-wrapper">
            <div class="d-flex dialer-number-wrapper">
              <button class="dialer-close-btn" disabled>
                <i class="fa fa-phone" aria-hidden="true"></i>
              </button>
              <input type="text" #ivrInputDialer class="dialer-input form-control text-right"
                [(ngModel)]="externalIvrInputNumber" readonly />
            </div>
            <div class="dialer-digits-row d-flex align-items-center">
              <button class="dialer-digit-btn left" (click)="clickingOnDialer('1')">1</button>
              <button class="dialer-digit-btn middle" (click)="clickingOnDialer('2')">2</button>
              <button class="dialer-digit-btn right" (click)="clickingOnDialer('3')">3</button>
            </div>
            <div class="dialer-digits-row d-flex align-items-center">
              <button class="dialer-digit-btn left" (click)="clickingOnDialer('4')">4</button>
              <button class="dialer-digit-btn middle" (click)="clickingOnDialer('5')">5</button>
              <button class="dialer-digit-btn right" (click)="clickingOnDialer('6')">6</button>
            </div>
            <div class="dialer-digits-row d-flex align-items-center">
              <button class="dialer-digit-btn left" (click)="clickingOnDialer('7')">7</button>
              <button class="dialer-digit-btn middle" (click)="clickingOnDialer('8')">8</button>
              <button class="dialer-digit-btn right" (click)="clickingOnDialer('9')">9</button>
            </div>
            <div class="dialer-digits-row d-flex align-items-center ">
              <button class="dialer-digit-btn left bg-white" (click)="clickingOnDialer('*')">*</button>
              <button class="dialer-digit-btn middle bg-white" (click)="clickingOnDialer('0')">0</button>
              <button class="dialer-digit-btn right bg-white" (click)="clickingOnDialer('#')">#</button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>