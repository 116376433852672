import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs/internal/firstValueFrom";
import { deleteSavedFilter, digitalEngagementApiPayload, editSavedFilter, getAllClients, getFilters, inboundSummaryApiResult, liveVoiceApiPayload, newFilter, outboundSummaryApiResult, reminderSummaryApiResult } from "./dashboard-interfaces";

@Injectable({
  providedIn: "root"
})

export class DashboardService {

  constructor(private http: HttpClient) { }

  taskRouterApi: string = "/api/v1/platform/twilio-task-router";
  digitalEngagementApiPath: string = 'api/v1/platform/digital-engagement-dashboard';
  liveVoiceApiPath: string = 'api/v1/platform/voice-engagement-dashboard';
  liveVoiceFilter: string = 'api/v1/platform/voice-dashboard-filter';
  digitalEngagementFilter: string = 'api/v1/platform/digital-engagement-dashboard-filter';
  deleteDigitalFilter: string = 'api/v1/platform/digital-engagement-dashboard-filter/';
  deleteLiveFilter: string = 'api/v1/platform/voice-dashboard-filter/';

  startCallDashboardMetricPooling() {
    return firstValueFrom(this.http.post(this.taskRouterApi + "/start-call-dashboard-metric-pooling", {})
      .pipe(responseData => {
        return responseData;
      }))
  }

  getDigitalEngagementClients() {
    return firstValueFrom(this.http.get<getAllClients>(this.digitalEngagementFilter + "/get-clients").pipe((responseData) => {
      return responseData;
    }))
  }

  getLiveVoiceClients() {
    return firstValueFrom(this.http.get(this.liveVoiceFilter + "/clients").pipe((responseData) => {
      return responseData;
    }))
  }

  getEmailPaymentSummary(filter: digitalEngagementApiPayload) {
    return firstValueFrom(this.http.post<reminderSummaryApiResult>(this.digitalEngagementApiPath + "/email-data", filter)
      .pipe(responseData => {
        return responseData;
      }));
  }

  getEmailClientSummary(filter: digitalEngagementApiPayload) {
    return firstValueFrom(this.http.post<reminderSummaryApiResult>(this.digitalEngagementApiPath + "/email-data-client", filter)
      .pipe(responseData => {
        return responseData;
      }));
  }

  getSmsPaymentSummary(filter: digitalEngagementApiPayload) {
    return firstValueFrom(this.http.post<reminderSummaryApiResult>(this.digitalEngagementApiPath + "/sms-data", filter)
      .pipe(responseData => {
        return responseData;
      }));
  }

  getSmsClientSummary(filter: digitalEngagementApiPayload) {
    return firstValueFrom(this.http.post<reminderSummaryApiResult>(this.digitalEngagementApiPath + "/sms-data-client", filter)
      .pipe(responseData => {
        return responseData;
      }));
  }

  getInboundContactSummary(filter: liveVoiceApiPayload) {
    return firstValueFrom(this.http.post<inboundSummaryApiResult>(this.liveVoiceApiPath + "/inbound-call-metrics", filter)
      .pipe(responseData => {
        return responseData;
      }));
  }

  getClientInboundContactSummary(filter: liveVoiceApiPayload) {
    return firstValueFrom(this.http.post<inboundSummaryApiResult>(this.liveVoiceApiPath + "/inbound-call-metrics-client", filter)
      .pipe(responseData => {
        return responseData;
      }));
  }

  getOutboundContactSummary(filter: liveVoiceApiPayload) {
    return firstValueFrom(this.http.post<outboundSummaryApiResult>(this.liveVoiceApiPath + "/outbound-call-metrics", filter)
      .pipe(responseData => {
        return responseData;
      }));
  }

  getClientOutboundContactSummary(filter: liveVoiceApiPayload) {
    return firstValueFrom(this.http.post<outboundSummaryApiResult>(this.liveVoiceApiPath + "/outbound-call-metrics-client", filter)
      .pipe(responseData => {
        return responseData;
      }));
  }

  getLiveVoiceFilters() {
    return firstValueFrom(this.http.get<getFilters>(this.liveVoiceFilter + "/all-filters")
      .pipe(responseData => {
        return responseData;
      }));
  }

  createLiveVoiceFilter(filter: newFilter) {
    return firstValueFrom(this.http.post(this.liveVoiceFilter, filter)
      .pipe(responseData => {
        return responseData;
      }));
  }

  updateLiveVoiceFilter(id: number, filter: editSavedFilter) {
    return firstValueFrom(this.http.put(this.liveVoiceFilter + `/${id}`, filter)
      .pipe(responseData => {
        return responseData;
      }));
  }

  getDigitalEngagementFilters() {
    return firstValueFrom(this.http.get<getFilters>(this.digitalEngagementFilter + "/all")
      .pipe(responseData => {
        return responseData;
      }));
  }

  createDigitalEngagementFilter(filter: newFilter) {
    return firstValueFrom(this.http.post(this.digitalEngagementFilter, filter)
      .pipe(responseData => {
        return responseData;
      }));
  }

  updateDigitalEngagementFilter(id: number, filter: editSavedFilter) {
    return firstValueFrom(this.http.put(this.digitalEngagementFilter + `/${id}`, filter)
      .pipe(responseData => {
        return responseData;
      }));
  }

  deleteSavedDigitalFilter(id: number) {
    return firstValueFrom(this.http.delete<deleteSavedFilter>(this.deleteDigitalFilter + id)
      .pipe(responseData => {
        return responseData;
      }));
  }

  deleteSavedLiveFilter(id: number) {
    return firstValueFrom(this.http.delete<deleteSavedFilter>(this.deleteLiveFilter + id)
      .pipe(responseData => {
        return responseData;
      }));
  }
}
